<template>
  <div class="logout-menu">
    <!--
    <div class="user" v-if="route == '/contracts'">
      <img alt="user" src="../assets/ico-user-b.svg" width="40"><br>
      {{ userName }}<br>
      {{ user.email }}<br>
      {{ user.username }}<br>
      <span class="ver-mas" @click="$router.push('/datas')">ver mas</span>
    </div>
    -->
    <!--<div class="logout" @click="logout()"><img alt="close" src="../assets/ico-close.svg" width="15"> CERRAR SESIÓN</div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LogoutMenu',
  data () {
    return {
      route: ""
    }
  },  
  mounted () {
    this.route = window.location.pathname
  },
  methods: {
    logout () {
      this.$http.get('/api/user_logout').then(response => {
        if(response.data['result'] == 'ok') {
          this.$store.commit('logout')
          this.$router.push("/")          
        }
      })
    }
  },
  computed: {
    ...mapGetters(['userName', 'user'])    
  }  
}
</script>

<style>
@media (min-width: 768px) {
  .logout-menu {
    margin: 15px auto;
    width: var(--widthContent);
  }

  .logout {
    display: block;
    float: right;
    color: var(--fucsia-1);
    cursor: pointer;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .logout {
    display: none;
  }
  .user {
    margin: 10px 0 0 10px;
  }  
}

.user {
  font-size: 14px;
  color: var(--azul-2);
  text-align: center;
}

.logout img {
  position: relative;
  top: 2px;
}

.ver-mas {
  cursor: pointer;
  color: var(--gris-2);
  text-decoration: underline;
}
</style>