<template>
  <div data-app class="login">    
    <h1 class="titulo">ACCESO ÁREA CLIENTES</h1>
    <div id="login-content" class="login-content">
      <h5>NIF o NIE del titular <img class="info" alt="info" src="../assets/ico-info-circle.svg" @click="dialog_nif=true"></h5>
      <input class="input-login" name="dni" placeholder="" size="small" v-model="dni" @keyup.enter="login()"/>
      <br>
      <h5>Contraseña <img class="info" alt="info" src="../assets/ico-info-circle.svg" @click="dialog_pass=true"></h5>
      <input id="pass" class="input-login" name="password" placeholder="" size="small" type="password" v-model="password" @keyup.enter="login()"/>
      <div class="show-pass">
        <label for="showpass">Mostrar contraseña </label>
        <input type="checkbox" id="showpass" name="showpass" @click="showpass()">
      </div>

      <div class="error-login">{{ error }}</div>
      <div id="boton-login" class="boton-login" @click="login()">Iniciar sesión</div>
      <div class="botones">
        <div class="crear"><router-link to="/password?type=0" class="olvido-link-new">Crear<br>Cuenta</router-link></div>
        <div class="recuperar"><router-link to="/password?type=1" class="olvido-link-new">Recuperar<br>contraseña</router-link></div>
      </div>

      <div class="necesitas">
        ¿Qué necesitas?
        <br>
        <div class="nico"><img class="nimg" src="../assets/ico-up-down-arrows-b.svg"></div><div class="ntexto">Consulta tu consumo de datos y llamadas</div>
        <div class="nico"><img class="nimg" src="../assets/ico-invoice-euro-b.svg"></div><div class="ntexto">Accede a tus facturas siempre que quieras.</div>
        <div class="nico"><img class="nimg" src="../assets/ico-contract-b.svg"></div><div class="ntexto">Consulta todos tus productos contratados.</div>
        <div class="nico"><img class="nimg" src="../assets/ico-star-b.svg"></div><div class="ntexto" style="line-height: 2.5;">¡Y muchas ventajas más!</div>

        <div class="entrar" @click="dialog_contact=true">¿No puedes entrar?</div>
      </div>

    </div>

    <div id="company-content" class="company-content">
      <h5>Selecciona la compañía</h5>
      <select class="input-select" v-model="company">
        <option :value="3">Conectividad Satélite</option>
        <option :value="2">Otros Productos</option>
      </select>
      <div class="error-company">{{ error_company }}</div>
      <div id="boton-company" class="boton-company" @click="selCompany()">Seleccionar</div>
    </div>  

    <v-dialog v-model="dialog_nif" width="250">
      <v-card>
        <v-card-text>
          <div class="dialog-text-padding">
            Recuerda incluir la letra de tu DNI en mayúscula.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_nif=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   

    <v-dialog v-model="dialog_pass" width="250">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Recuerda que tu contraseña contiene 8 dígitos, combinando números con letras mayúsculas y minúsculas.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_pass=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   

    <v-dialog v-model="dialog_contact" width="250">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Contacta con nosotros.<br>
            <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_contact=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    

    <v-dialog v-model="dialog_error" width="300">
      <v-card>
        <v-card-text>
          <div class="dialog-text-padding">            
            <b>Recuerda.</b>
            <ul>
              <li>Incluir la letra de tu DNI o NIE en mayúscula.</li>
              <li>Tu contraseña contiene 8 digitos combinando números con letras mayúsculas y minúsculas.</li>
              <li>
                Contacta con nosotros<br>
                <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_error=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        

  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      dni: '',
      password: '',
      error: '',
      error_company: '',
      company: 0,
      dialog_nif: false,
      dialog_pass: false,
      dialog_contact: false,
      dialog_error: false
    }
  },
  mounted () {
    this.$http.get('/api/customer_info').then(response => {
      if(response.data['result'] == 'ok') {
        this.$store.commit('login', response.data['data'])
        this.$router.push("/contracts")  
      } else {
        this.$store.commit('logout')
        this.$router.push("/")              
      }
    }).catch((e) => {
      this.$store.commit('logout')
      this.$router.push("/")   
      console.log(e)
    })   
  },  
  methods: {
    showpass () {
      let password = document.getElementById('pass')
      let type = password.getAttribute('type') === 'password' ? 'text' : 'password'
      password.setAttribute('type', type)
    },
    login () {
      if (this.validateSend()) {
        this.error = 'Enviando datos...'
        document.getElementById('boton-login').classList.add("hide");        
        let payload = {
          username: this.dni,
          password: this.password,
        }
        this.$http.post('/api/user_login', payload).then(response => {
          if(response.data['result'] == 'ok') {
            if (response.data['company'] == 0) {
              // si la company es 0 tiene mas de una compañia
              document.getElementById('login-content').classList.add("hide")
              document.getElementById('company-content').classList.add("show")
            } else {          
              this.$http.get('/api/customer_info').then(response => {
                if(response.data['result'] == 'ok') {
                  this.$store.commit('login', response.data['data'])
                  this.$router.push("/contracts")
                } else {
                  this.dialog_error = true
                  this.error = 'Usuario o contraseña incorrectos'
                  document.getElementById('boton-login').classList.remove("hide")                  
                }            
              }).catch((e) => {
                console.log(e)
              })   
            }               
          } else {
            this.dialog_error = true
            this.error = 'Usuario o contraseña incorrectos'
            document.getElementById('boton-login').classList.remove("hide")
          }
        }).catch(() => {
          this.dialog_error = true
          this.error = 'Usuario o contraseña incorrectos'
          document.getElementById('boton-login').classList.remove("hide")
        })
      }
    },
    selCompany () {
      if (this.company == 0) {
        this.error_company = 'Selecciona una compañia'
        document.getElementById('boton-login').classList.remove("hide");        
      }
      let payload = {
        company: this.company
      }      
      this.$http.post('/api/set_token', payload).then(response => {
        if(response.data['result'] == 'ok') {
          this.$http.get('/api/customer_info').then(response => {
            if(response.data['result'] == 'ok') {
              this.$store.commit('login', response.data['data'])
              this.$router.push("/contracts")
            }                
          }).catch((e) => {
            console.log(e)
          })
        }                
      }).catch((e) => {
        console.log(e)
      })  
    },
    validateSend () {
      if (this.dni == '' || this.password == '') {
        this.dialog_error = true
        this.error = 'Debes introducir un nombre de usuario y una contraseña'  
        return false
      } else {
        this.error = ''
        return true
      }
    }
  }
}
</script>

<style>
.titulo-acceso {
  margin: 50px 0 15px 0;
  text-align: center;
}

.login-content {
  text-align: center;
  padding-bottom: 15px;
}

.company-content {
  text-align: center;
  padding-bottom: 150px;  
  display: none;
}

h5 {
    text-align: left;
    width: 21%;
    margin: 15px auto 0 auto;
    font-weight: normal;
    font-size: 16px;
}

.info {
  height: 17px;
  vertical-align: middle;
  cursor: pointer;
}

.input-login {
  width: 85%;
  margin: 5px auto;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  color: #BABABA;
  padding: 8px;
}

.input-select {
  margin: 10px 5px 5px 0;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  padding: 0 0 0 5px;
  width: 21%;
}

.olvido-login, .show-pass {
  margin: 5px auto;
  text-align: right;
  color: var(--azul-2);
  font-size: 16px;
  width: 90%;
  cursor: pointer;
}

.show-pass {
  color: var(--gris-3);
}

.olvido-link, .registry-link {
  text-decoration: none;
  color: var(--azul-2);
}

.error-login, .error-company {
  margin: 50px auto 0 auto;
  text-align: center;
  color: var(--fucsia-1);
  font-size: 18px;
  width: 90%;
}

.boton-login {
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;  
}

.boton-company {
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;  
}

.telefono-movil {
  display: none;
}

.botones {
  margin: 25px auto;
  width: 20%;
}

.crear {
  float: left;
  padding: 5px;
  text-align: center;
  color: var(--gris-3);
  font-size: 15px;
  border: 2px solid var(--azul-2);
  width: 125px;
}

.recuperar {
  float: right;
  padding: 5px;
  text-align: center;
  color: var(--gris-3);
  font-size: 15px;
  border: 2px solid var(--fucsia-1); 
  width: 125px;
}

.olvido-link-new {
  text-decoration: none;
  color: var(--gris-3);
}

.necesitas {
  margin: 25px auto;
  width: 20%;  
  text-align: center;
  color: var(--azul-1);
  font-size: 15px;
  padding-top: 80px;
}

.nico {
  clear: both;
  float: left;
  width: 20%;
  margin: 15px 0 0 0;
}

.nimg {
  height: 35px;
  vertical-align: middle;
}

.ntexto {
  float: left;
  color: var(--gris-3);
  width: 80%;
  text-align: left;
  margin: 15px 0 0 0;
}

.entrar {
  clear: both;
  padding: 30px 0 0 0;
  cursor: pointer;
}



@media (min-width: 768px) {
  .login-content .input-login, 
  .login-content .olvido-login, 
  .login-content .boton-login, 
  .company-content .boton-company, 
  .show-pass {
    width: 20%;
  }
}

@media (max-width: 1400px) {
  .crear, .recuperar {
    width: 110px;    
  }
}

@media (max-width: 768px) {
  h5 {
    clear: both;
    margin-top: 40px;
    text-align: left!important;
    width: 85%!important;  
  }  

  .telefono-pc {
    display: none!important;
  }

  .telefono-movil {
    display: block;
    padding: 15px 0 0 0;
    font-weight: bold;    
    color: var(--negro);
    font-size: 18px;
  }

  .telefono-movil a {
    font-weight: bold;
    text-decoration: none;
    color: var(--negro);
  }

  .titulo-acceso {
    margin-top: 25px!important;
  }

  .botones {
    width: 85%;
  }

  .input-select {
    width: 85%!important;
  }  

  .necesitas {
    width: 90%;  
  }    
}
</style>