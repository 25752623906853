<template>
  <div id="app">
    <toggle-nav></toggle-nav>
    <router-view/>
  </div>
</template>

<script>
import ToggleNav from './components/ToggleNav.vue'

export default {
  name: 'app',
  mounted: function () {
    if (this.$route.name != 'Nuevo Password') {
      this.$http.get('/api/customer_info').then(response => {
        if(response.data['result'] == 'ok') {
          this.$store.commit('login', response.data['data'])
          if (this.$router.history._startLocation != '/') this.$router.go(-1)
        } else {
          this.$store.commit('logout')
          this.$router.push("/")              
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })
    }
  },
  methods: {
  },
  computed: {
  },
  components: { ToggleNav }
}
</script>

<style>
:root {
    --rojo: #FF0000;
    --verde: #12BA18;
    --azul-1: #0086B4;
    --azul-2: #41B6E6;
    --azul-3: #7EE8FF;
    --fucsia-1: #FF008C;
    --gris-1: #030303;
    --gris-2: #2E2E2E;
    --gris-3: #575757;
    --gris-4: #B1B1B1;
    --gris-5: #CECECE;
    --gris-6: #E5E5E5;
    --blanco: #FFFFFF;

    --widthContent: 1024px;
}

body,html {
    width: 100%;
    border: 0;
    margin: 0;
    font-family: Montserrat, sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

.show {
  display: block!important;
}

.hide {
  display: none!important;
}

.titulo {
  margin: 0;
  padding: 15px 0;
  color: var(--azul-1);
  text-align: center;
  font-size: 25px;
  border-top: 1px solid var(--azul-2);
  box-shadow: 0px -2px 2px 1px rgb(0 0 0 / 20%);
}

.titulo-pequeño {
  font-weight: 100;
  color: var(--azul-2);
}

.content {
  clear: both;
  padding: 15px;
}

.dialog-text-padding {
  padding: 15px 0 0 0;
}

a {
  text-decoration: none;
  color: var(--azul-2);
}

select {
  width: auto;
  padding: 5px;
}

@media (min-width: 1024px) {
  .content {
    margin: 0 auto;
    width: var(--widthContent);
  }
}

@media (max-width: 768px) {
  .titulo {
    font-size: 20px;
  }  
}
</style>
