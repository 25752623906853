<script>
import { Doughnut } from 'vue-chartjs'
export default {
  extends: Doughnut,
  props: ['chart'],
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [],
    },
    options: {
      legend: {
        display: false,
        color: ['#FF008C']
      }
    }
  }),
  mounted () {
    this.chartdata.labels = this.chart.labels
    this.chartdata.datasets = this.chart.datasets
    this.renderChart(this.chartdata, this.options) 
  },
  methods: {
  },
  watch: {
    chart () {
      this.chartdata.labels = this.chart.labels
      this.chartdata.datasets = this.chart.datasets
      this.renderChart(this.chartdata, this.options) 
    }
  }
}
</script>

<style>

</style>