import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    user: {},
  },
  getters: {
    logged: state => {
      return state.logged
    },
    userName: state => {
      return state.user.name
    },    
    user: state => {
      return state.user
    },
  },
  actions: {
  },
  mutations: {
    login: (state, data) => {
      state.user = data
      state.logged = true
    },
    logout: state => {
      state.logged = false
    }
  }
})
