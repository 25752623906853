<template>
  <div class="navbar">
    <img class="logo" alt="eurona" src="../assets/logo-eurona.svg">
    <div id="menu-pc" v-if="logged">
      <div class="sec-menu" v-for="button in buttons" @click="goToPage(button.route)" :key="'des-' + button.text">
        <div v-if="route == ('/' + button.route)" class="sel">
          <img class="sec-img" alt="" :src="require('@/assets/' + button.img)" :width="button.width">
          <span style="padding-left: 10px; line-height: 1.2">{{ button.text }}</span>          
        </div>
        <div v-else>
          <img class="sec-img" alt="" :src="require('@/assets/' + button.img)" :width="button.width">
          <span style="padding-left: 10px; line-height: 1.2">{{ button.text }}</span>
        </div>
      </div>

      <div class="logout" @click="logout()">
        <img alt="cerrar" src="../assets/ico-close.svg" width="15"> SALIR
      </div>      
    </div>

    <div id="logout-mov" v-if="logged">
      <div class="sec-close-mov" @click="logout()"><img alt="close" src="../assets/ico-close.svg" width="15"><br>SALIR</div>
      <!--<div class="sec-misdatos-mov" @click="goToPage('datas')">
        <img class="sec-img" alt="" :width="29" :src="require('@/assets/ico-misdatos.svg')">
        <div class="but-txt">Mis Datos</div>
      </div>-->
    </div>

    <div id="menu-movil" v-if="logged">
      <div class="sec-menu-mov" :id="'mov-' + button.route" v-for="button in buttons" @click="goToPage(button.route)" :key="'mov-' + button.text">
        <div v-if="route == ('/' + button.route)" class="sel">
          <img class="sec-img" alt="" :src="require('@/assets/' + button.img)" :width="button.width">
          <div class="but-txt">
            {{ button.text }}
          </div>
        </div>
        <div v-else>
          <img class="sec-img" alt="" :src="require('@/assets/' + button.img)" :width="button.width">
          <div class="but-txt">
            {{ button.text }}
          </div>
        </div>        
      </div>
    </div>

    <div class="whatsapp-flotante">
      <a href="https://api.whatsapp.com/send/?phone=34910780088&text&app_absent=0" target="_blank">
        <img alt="contacta con nosotros" :width="50" :src="require('@/assets/ico-whatsapp-flotante.png')" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ToggleNav',
  data () {
    return {
      buttons: [
        {
          text: 'Mis Productos',
          img: 'ico-contratado.svg',
          route: 'contracts',
          width: '31'          
        }, {
          text: 'Facturas',
          img: 'ico-facturas.svg',
          route: 'invoices',
          width: '25'
        }, /*{
          text: 'Consumos',
          img: 'ico-consumos.svg',
          route: 'consumes',
          width: '32'
        },*/ {
          text: 'Mis Datos',
          img: 'ico-misdatos.svg',
          route: 'datas',
          width: '29'
        }, {
          text: 'Ayuda',
          img: 'ico-faqs.svg',
          route: 'help',
          width: '30'
        }
      ],
      route: ""
    }
  },
  mounted () {
    this.route = window.location.pathname
  },
  methods: {
    goToPage (page) {  
      if(page == 'search'){
        window.location.assign('https://www.eurona.es/faq')
      }else{
        this.$router.push('/' + page)
        this.route = window.location.pathname
      }
    },
    logout () {
      this.$http.get('/api/user_logout').then(response => {
        if(response.data['result'] == 'ok') {
          this.$store.commit('logout')
          this.$router.push("/")          
        }
      })
    }    
  },
  computed: {
    ...mapGetters(['logged'])
  }  
}
</script>

<style>
.logo {
  margin: 10px;
  width: 150px;
}

#menu-pc {
  float: right;
  display: none;
}

#menu-pc .logout {
  padding: 24px 10px 0 0;
  color: var(--fucsia-1);
}

.sel {
  padding: 1px 5px 9px 5px;
  background-color: var(--azul-3);
  border-radius: 5px 5px 0 0;
  box-shadow:2px -1px 2px 1px rgb(0 0 0 / 20%);
}

.sec-menu {
  float: left;
  padding: 7px 25px 0 0;
  cursor: pointer;
}

.sec-img {
  position: relative;
  top: 5px;
}

.but-txt {
  font-size: 13px;
}

.sec-menu-search{
  float: left;
  margin: 0 20px;
  padding: 25px 25px 0 0;
  cursor: pointer;
}

#sec-menu-search-mov {
  position: relative;
  top: 0;
  float: right;
  margin: 0 20px;
  padding: 25px 0 0 0;
  cursor: pointer;
}

#logout-mov {
  position: relative;
  top: 0;
  float: right;
  margin: 0 20px;
  padding: 0;
  cursor: pointer;
  color: var(--fucsia-1);
}

.sec-misdatos-mov {
  text-align: center;
  float: right;
  color: var(--gris-1);
  margin: 2px 0 0 0;
}

.sec-close-mov{
  float: right;
  padding: 15px 0 0 15px;
  text-align:center;
  font-size: 13px;
  line-height: 1.6;
}

#menu-movil {
  position: fixed;
  bottom: 0;
  height: 65px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  padding: 5px 0;  
  background-color: var(--blanco);
  z-index: 100;
}

.sec-menu-mov {
  display: grid;
  grid-template-rows: auto;
  text-align: center;
  cursor: pointer;
}

/*
#mov-datas {
  display: none;
}
*/

.whatsapp-flotante {
  position: fixed;
  bottom: 65px;
  right: 50px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .logo {
    width: 120px;
  }

  .navbar {
    margin: 0 auto;
    width: var(--widthContent);
  }

  #menu-pc {
    display: block;
  }

  #menu-movil, #sec-menu-search-mov, #logout-mov  {
    display: none;
  }
  .telefono-pc {
    display: block;
    font-weight: bold;    
    color: var(--negro);    
    position: fixed;
    top: 15px;
    font-size: 20px;
    text-align: center;
    margin-left: 340px;
  }
}

@media (max-width: 1024px) {
  .whatsapp-flotante {
    top: 75px;
    right: 5px;
  }
}
</style>