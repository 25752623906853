<template>
  <div data-app class="help">    
    <h1 class="titulo">
      Área de Clientes<br><br>
      <img alt="user" src="../assets/ico-ayuda-y-soporte.png" width="70"><br>
      <span class="titulo-pequeño">Ayuda y Soporte</span>
    </h1>
    <logout-menu></logout-menu>
    <div class="content">
      <div class="contenedor">
        <div v-for="item of elements" :key="item.data" @click="event(item)">
          <div class="image">
            <img alt="ayuda" :src="require('../assets/' + item.icon)" width="50">
          </div>
          <div class="texto">{{ item.name }}</div>
          <div class="flecha">></div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog.display" width="500">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding" v-if="dialog.type == 'soporte'">
            <div class="texto-dialogo">
              <span class="titulo-dialogo">Ayúdanos a ayudarte</span><br>
              <br>
              ¿Crees que algo va mal con tu conexión?<br>
              <br>
              <b>1. Verifica la conexión:</b> en el equipo negro que tienes en casa verifica que la luz de “System” está encendida.<br>
              <b>2. Reinicia tus equipos.</b> ¿Cómo se hace? Es muy sencillo, sigue el cable negro que va conectado a tus equipos y desconéctalo de la corriente durante unos minutos. Vuelve a enchufarlo. Espera a que enciendan las luces y prueba si el servicio se ha recuperado.<br>
              <b>3. Código de estado:</b> Pincha <a href="https://192.168.0.1" target="_blank">aquí</a> y busca el apartado “System State Code”, si aparece 0.0.0 el servicio debería funcionar correctamente.<br>
              <br>
              Si ninguna de estas acciones ha funcionado no te preocupes, ponte en contacto con nuestro equipo de Soporte Técnico, estaremos encantados de atenderte lo más rápido posible. <br>
            </div>
          </div>
          <div class="dialog-text-padding" v-if="dialog.type == 'contacta'">
            <span class="titulo-dialogo">Información de contacto</span>
            <v-divider style="margin: 20px 0px;"></v-divider>
            <v-row style="flex-direction: row;">
              <div style="width: max-content">
                <img alt="mail" src="../assets/ico-correo-electronico.png" width="40"/>
              </div>
              <v-col>
                <span>Correo electrónico a <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a></span>
              </v-col>
            </v-row>
            <v-row>
              <div>
                <img alt="telefono" src="../assets/ico-por-telefono.png" width="40"/>
              </div>
              <v-col>
                <span>Por Teléfono al <a href="tel:910 78 00 88">910 78 00 88</a></span>
              </v-col>
            </v-row>
            <v-row>
              <div>
                <img alt="whatsapp" src="../assets/ico-whatsapp.png" width="40"/>
              </div>
              <v-col>
                <span>Por WhatsApp al <a href="https://api.whatsapp.com/send/?phone=34910780088&text&app_absent=0" target="_blank">910 78 00 88</a></span>
              </v-col>
            </v-row>

            <v-divider style="margin: 20px 0px;"></v-divider>
            Síguenos en nuestras Redes Sociales y entérate de las últimas novedades
            <v-divider style="margin: 20px 0px;"></v-divider>

            <div class="center">
              <a href="https://www.instagram.com/euronaonline/?hl=es" target="_blank">
                <img alt="instagram" src="../assets/ico-instagram.jpg" width="35"/>
              </a>
              <a href="https://www.facebook.com/euronaonline/?locale=es_ES" target="_blank">
                <img alt="instagram" src="../assets/ico-facebook.png" width="35"/>
              </a>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog.display=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Help',
  data () {
    return {
      elements: [{
        data: 'soporte',
        icon: 'ico-soporte-tecnico.png',
        name: 'Soporte técnico',
        modal: true
      }, {
        data: 'https://www.eurona.es/eurona-libertad',
        icon: 'ico-eurona-libertad.png',
        name: 'Eurona Libertad',
        modal: false
      }, {
        data: 'https://www.eurona.es/faq',
        icon: 'ico-ayuda-y-soporte.png',
        name: 'Preguntas frecuentes',
        modal: false
      }, {
        data: 'https://www.eurona.es/blog',
        icon: 'ico-sabias-que.png',
        name: '¿Sabías qué?',
        modal: false
      }, {
        data: 'contacta',
        icon: 'ico-contacta-con-nosotros.png',
        name: 'Contacta con nosotros',
        modal: true
      }],
      dialog: {
        display: false,
        type: ''
      }
    }
  },
  mounted () {
    if (!this.logged) {
      this.$router.push("/")
    }
  },  
  methods: {
    event (element) {
      if (element.modal) {
        this.dialog.display = true
        this.dialog.type = element.data
      } else {
        window.open(element.data, '_blank')
      }
    }
  },
  computed: {
    ...mapGetters(['logged'])
  },  
  components: { LogoutMenu }
}
</script>

<style scoped>
.contenedor {
  width: 325px;
  margin: auto;
}
 .image {
  clear: both;
  float: left;
  border-bottom: 1px solid var(--azul-2);
  margin: 15px 0 0 0px;
  cursor: pointer;
 }
 .texto {
  float: left;
  border-bottom: 1px solid var(--azul-2);
  margin: 15px 0 0 0px;
  padding: 20px 0px 0 15px;
  height: 55px;
  width: 222px;
  cursor: pointer;
 }

 .flecha {
  float: left;
  border-bottom: 1px solid var(--azul-2);
  margin: 15px 0 0 0px;
  padding: 20px;
  height: 55px;
  cursor: pointer;
 }

 .titulo-dialogo {
  color: var(--azul-2);
  font-size: 20px;
 }

 .texto-dialogo {
  text-align: center;
 }


.row, .row > span {
  padding: 10px;
}

.center {
  text-align: center;
}

.center > * {
  margin: 10px;
}
</style>