<template>
  <div data-app class="invoice">    
    <h1 class="titulo">
      Área de Clientes<br><br>
      <img alt="user" src="../assets/ico-facturas-b.svg" width="40"><br>
      <span class="titulo-pequeño">Tus Facturas</span>
    </h1>
    <logout-menu></logout-menu>
    <div class="content">

      <div class="selectors">
        <span class="anio">Seleccion Año</span><br>
        <select class="input-select" v-model="year" v-on:change="getInvoicesDate()" v-on:update-year="year = $event">
          <option v-for="(item, index) in years" :key="index" :label="item.text" :value="item"><b>{{ item }}</b></option>
        </select>
        <select class="input-select hide" v-model="month" v-on:change="getInvoicesDate()"  v-on:update-month="month = $event">
          <option v-for="(item, index) in months" :key="index" :label="item.text" :value="item.value">{{ item.text }}</option>
        </select>
      </div>

      <div class="price-selector-container">
        <div class="price-selector" v-for="(item, index) in curentInvoice" :key="item.id">
          <div class="invoice-date">{{ curentInvoice[index].date.split('-')[2].split(' ')[0] }}-{{ curentInvoice[index].date.split('-')[1] }}-{{ curentInvoice[index].date.split('-')[0] }}</div>
          {{ curentInvoice[index].price }} €<br>
          <a :href="curentInvoice[index].url" target="_blank"><span class="download-invoice">Descargar factura <b>&darr;</b></span></a>
        </div>
      </div>

      <div id="scrollMov" @scroll="handleScroll">
        <div id="scrollMovMin">
          <div id="scroll-left" @click="moveScroll('lt')">
            <img src="../assets/arrow.png" height="80"/>
          </div>
          <div id="scroll-right" @click="moveScroll('rt')">
            <img class="img-rotation" src="../assets/arrow.png" height="80"/>
          </div>
          <line-chart v-bind:year="year"></line-chart>
        </div>
      </div>

      <div class="invoice-contract">
        <div class="invoice-contract-titulo">¿No has podido pagar alguna factura?</div>
        <div>Visita nuestro portal de pagos y no pierdas ni un minuto de conexión.</div>
        <div class="boton-pago" onclick="window.location.href = 'https://portalpagos.eurona.com/log_in_ref'">
          <img src="../assets/ico-pago-pendiente-w.svg" width="25"/>
          Pagos<br>Pendientes
        </div>
        <div class="boton-cuenta" @click="dialog_contact=true">
          <img src="../assets/ico-cambiar-cuenta-w.svg" width="25"/>
          Cambiar<br>Cuenta
        </div>
      </div>

      <embed id="pdf" class="hide" src="" type="application/pdf" width="100%" height="1000px" />
    </div>
    
    <v-dialog v-model="dialog_contact" width="300">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Descarga <a href='https://eurona.es/static/images/821/formulario-cambio-domiciliacion-bancaria.pdf' target="_blank">aquí</a> tu formulario,
            una vez cumplimentado por el titular del servicio, deberás enviar la documentación a
            <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_contact=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import LineChart from '../components/LineChart.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Invoice',
  data () {
    return {
      curentInvoice: [],
      invoices: '',
      services: '',
      year: new Date().getFullYear(),
      years: [],
      month: '',
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      scrollLeftMax: 0,
      dialog_contact: false
    }
  },

  mounted () {
    if (!this.logged) {
      this.$router.push("/")
    } else {
      this.getInvoices()
      document.getElementById("scrollMov").scrollLeft = document.getElementById("scrollMovMin").scrollWidth
      this.scrollLeftMax = document.getElementById("scrollMov").scrollLeft 
      this.getServices()
    }
  },  
  methods: {
    getInvoices () {
      this.$http.get('/api/customer_invoices').then(response => {
        if(response.data['result'] == 'ok') {          
          this.invoices = response.data['data']
          // buscamos los años de las facturas
          for (let i=0; this.invoices.count > i; i++) {
            if ( this.invoices.data[i].date != null) {
              let year = this.invoices.data[i].date.split('-')[0]
              if (year < 2018) break;
              if (!this.years.includes(year)) { this.years.push(year) }
            }
          }
          if ( this.invoices.data[0].date != null) {
            this.month = this.invoices.data[0].date.split('-')[1]  
            this.getInvoicesDate ()
          }  
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })      
    },
    getServices () {
      this.$http.get('/api/customer_services').then(response => {
        if(response.data['result'] == 'ok') {
          this.services = response.data['data']
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })      
    },
    getInvoiceInfo (id) {
      this.$http.get('/api/customer_invoice_info/' + id).then(response => {
        if(response.data['result'] == 'ok') {
          this.services = response.data['data']
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })      
    },    
    getDownloadInvoice (id) {
      window.open('/api/customer_download_invoice_pdf/' + id, '_blank')
      /*
      this.$http.get('/api/customer_download_invoice/' + id).then(response => {
        let pdfWindow = window.open('/api/customer_download_invoice_pdf/' + id)
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data.data)+"'></iframe>")
      }).catch((e) => {
        console.log(e)
      })
      */      
    },            
    getInvoicesDate () {
      this.curentInvoice = []
      for (let i=0; this.invoices.count > i; i++) {
        if ( this.invoices.data[i].date != null) {
          if (this.year == this.invoices.data[i].date.split('-')[0] && this.month == this.invoices.data[i].date.split('-')[1]) {
            this.curentInvoice.push(this.invoices.data[i])
          }
        }
      }
    },
    updateMonth (month) {
      this.month = month
      this.getInvoicesDate()
    },
    handleScroll () {
      let scrollLeft = document.getElementById("scrollMov").scrollLeft 
      if (scrollLeft <= 0) {
        document.getElementById("scroll-left").classList.add("hide")
      } else {
        document.getElementById("scroll-left").classList.remove("hide")
      }
      if (scrollLeft >= this.scrollLeftMax) {
        document.getElementById("scroll-right").classList.add("hide")
      } else {
        document.getElementById("scroll-right").classList.remove("hide")
      }
    },
    moveScroll (mov) {
      let scrollLeft = document.getElementById("scrollMov").scrollLeft 
      if (mov == 'lt') {
        document.getElementById("scrollMov").scrollLeft = scrollLeft - 20
        return
      }
      if (mov == 'rt') {
        document.getElementById("scrollMov").scrollLeft = scrollLeft + 20
        return
      }      
    }    
  },
  computed: {
    ...mapGetters(['logged'])
  },
  components: { LogoutMenu, LineChart }
}
</script>

<style>
.chart-container {
  position: relative;
  margin: auto;
  height: 180vh;
  width: 80vw;
}

.selectors {
  float: left;
  font-size: 30px;
  font-weight: bold;
  margin: 5px 0 20px 15px;
}

.price-selector-container {
  float: right;
}

.price-selector {
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0 20px 15px;
  line-height: 0.7;
  text-align: right;
}

.invoice-date {
  text-align: center;
  padding: 0 0 10px 0;
  font-size: 11px;
  color: var(--gris-3);
}

.download-invoice {
  font-size: 10px;
  cursor: pointer;
  color: var(--fucsia-1);
  font-weight: 100;
}

.invoices {
  clear: both;
}

#scroll-left, #scroll-right {
  display: none;
  width: 40px;
  height: 60px;
  border-radius: 5px;
  opacity: 0.3;
  position: absolute;
  top: 56%;
  font-family: "Arial Narrow";
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}

#scroll-left {
  left: 0;
}

#scroll-right {
  right: 0;
}

.img-rotation {
  transform: rotate(180deg); 
}

.invoice-contract {
  position: relative;
  clear: both;
  margin: 25px auto;
  width: 33%;
  height: 190px;
  color: var(--gris-3);
}

.invoice-contract-titulo {
  color: var(--azul-2);
  text-align: center;
}

.invoice .boton-pago {
  position: relative;
  clear: both;
  float: left;
  margin: 15px auto;
  padding: 10px 0 10px 25px;
  color: var(--blanco);
  background-color: var(--azul-1);
  font-size: 15px;
  width: 160px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.invoice .boton-cuenta {
  position: relative;
  float: right;
  margin: 15px auto;
  padding: 10px 0 10px 15px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 15px;
  width: 160px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.boton-pago img {
  position: absolute;
  top: 15px;
  left: 20px;
}

.boton-cuenta img {
  position: absolute;
  top: 17px;
  left: 20px;
}

#scrollMov {
  width: 100%; 
  clear: both;
}

#scrollMovMin {
  min-width: 1000px;
}

.anio {
  font-size: 15px;
}

.selectors .input-select {
  margin: 0 5px 0 0;
  height: 28px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -webkit-appearance: auto;
  padding: 5px;
  width: 100px;
}

@media (min-width: 1024px) {
  #bar-chart {
    height: 350px!important;
  }
}

@media (max-width: 1024px) {
  canvas {
    height: 70vw!important;
  }

  #scrollMov {
    overflow: scroll;
  }

  #scrollMovMin {
    overflow: scroll;
  }

  #scroll-left, #scroll-right {
    display: block;
  }

  .invoice-contract {
    width: auto;
  }
}

</style>