<template>
  <div data-app class="contact">    
    <h1 class="titulo">Área de Clientes</h1>
    <div class="user">
      <img alt="user" src="../assets/ico-user-b.svg" width="40"><br>
      {{ userName }}<br>
      {{ user.email }}<br>
      {{ user.username }}<br>
      <span class="ver-mas" @click="$router.push('/datas')">ver mas</span>      

      <div class="surveys-productos" v-if="surveys != ''">
        {{ surveys.name }} <br>entrando en Mis Datos
      </div>
    </div>

    <logout-menu></logout-menu>
    <div id="company-content" class="company-content">
      <h5>Selecciona la compañía</h5>
      <select class="input-select" v-model="company" @change="selCompany()">
        <option value="3">Conectividad Satélite</option>
        <option value="2">Otros Productos</option>
      </select>
    </div>
    <div class="content">
      <div class="contact-info" v-for="(item, index) in services" :key="index">

          <div v-if="haveData(item.area, 'internet')">
            <div class="sec-tarifa" v-for="(item, index) in item.data"  :key="index">
              <div class="img"><img alt="user" src="../assets/ico-router-s.svg" width="45"/></div>
              <div class="tarifa">{{ item.area }}</div>  
              <div v-if="item.address.street !== null" class="tarifa-area">{{ item.address.street }}, {{ item.address.city }}</div>

              <div class="des-corta">{{ item['short_description'] }}<br><span v-if="item['short_description'] !== item['service']">{{ item['service'] }}</span></div>
              <!--
              <div v-if="item['tokens'] != ''">
                <div class="tokens" v-for="(item, index) in item['tokens']" :key="index">
                  <div class="img"><img alt="user" src="../assets/ico-bono.svg" width="22"></div>
                  <div class="name">{{ item['name'] }}</div>                
                </div>
              </div>
              -->
              <!--
              <div v-if="item['children'] != ''">
                <div class="children" v-for="(item, index) in item['children']" :key="index">
                  <div class="img">
                    <img v-if="haveData(item['area'], 'Internet')" alt="user" src="../assets/ico-router-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Móvil') || haveData(item['area'],'Movil')" alt="user" src="../assets/ico-mobile-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Voz')" alt="user" src="../assets/ico-fijo-s.svg" width="35">
                  </div>
                  <div class="description">{{ item['short_description'] }}</div>
                  <div class="service" v-if="item['short_description'] !== item['service']">{{ item['service'] }}</div>
                </div>
              </div>
              -->
            </div>
          </div>

          <div v-if="haveData(item.area, 'movil') && !haveData(item.area, 'internet')">
            <div class="sec-tarifa" v-for="(item, index) in item.data"  :key="index">
              <div class="img"><img alt="user" src="../assets/ico-mobile-s.svg" width="45"/></div>
              <div class="tarifa">{{ item.area }}</div>
              <div class="tarifa-area">{{ item.phonenumber_mobile }}</div>

              <div class="des-corta">{{ item['short_description'] }}<br><span v-if="item['short_description'] !== item['service']">{{ item['service'] }}</span></div>
              <!--
              <div v-if="item['tokens'] != ''">
                <div class="tokens" v-for="(item, index) in item['tokens']" :key="index">
                  <div class="img"><img alt="user" src="../assets/ico-bono.svg" width="22"></div>
                  <div class="name">{{ item['name'] }}</div>                
                </div>
              </div>
              -->
              <!--
              <div v-if="item['children'] != ''">              
                <div class="children" v-for="(item, index) in item['children']" :key="index">
                  <div class="img">
                    <img v-if="haveData(item['area'], 'Internet')" alt="user" src="../assets/ico-router-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Móvil') || haveData(item['area'],'Movil')" alt="user" src="../assets/ico-mobile-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Voz')" alt="user" src="../assets/ico-fijo-s.svg" width="35">
                  </div>
                  <div class="description">{{ item['short_description'] }}</div>
                  <div class="service" v-if="item['short_description'] !== item['service']">{{ item['service'] }}</div>
                </div>
              </div>
              -->
            </div>        
            <a class="te-interesa" href="https://eurona.es/static/images/821/comandos-movil.pdf" target="_blank">Te interesa</a>
          </div>  

          <div v-if="haveData(item.area, 'voz') && !haveData(item.area, 'internet') && !haveData(item.area, 'movil') ">
            <div class="sec-tarifa" v-for="(item, index) in item.data"  :key="index">
              <div class="img"><img alt="user" src="../assets/ico-fijo-s.svg" width="45"/></div>
              <div class="tarifa">{{ item.area }}</div>
              <div class="tarifa-area">{{ item.phonenumber_fix }}</div>

              <div class="des-corta">{{ item['short_description'] }}<br><span v-if="item['short_description'] !== item['service']">{{ item['service'] }}</span></div>
              <!--
              <div v-if="item['tokens'] != ''">
                <div class="tokens" v-for="(item, index) in item['tokens']" :key="index">
                  <div class="img"><img alt="user" src="../assets/ico-bono.svg" width="22"></div>
                  <div class="name">{{ item['name'] }}</div>                
                </div>
              </div>
              -->
              <!--
              <div v-if="item['children'] != ''">              
                <div class="children" v-for="(item, index) in item['children']" :key="index">
                  <div class="img">
                    <img v-if="haveData(item['area'], 'Internet')" alt="user" src="../assets/ico-router-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Móvil') || haveData(item['area'],'Movil')" alt="user" src="../assets/ico-mobile-s.svg" width="35">
                    <img v-if="haveData(item['area'],'Voz')" alt="user" src="../assets/ico-fijo-s.svg" width="35">
                  </div>
                  <div class="description">{{ item['short_description'] }}</div>
                  <div class="service" v-if="item['short_description'] !== item['service']">{{ item['service'] }}</div>
                </div>
              </div>
              -->
            </div>            
          </div> 
      </div>
      <div class="contact-info">
          <div class="facturas" @click="$router.push('/invoices')">Facturas</div>
          <div class="actualizar" @click="dialog_actualizar=true">Actualizar</div>

          <div class="boton-servicio" onclick="window.location.assign('https://www.eurona.es/club-conectados')">
            <img src="../assets/ico-nuevo-servicio-w.svg" width="25"/>
            Club<br>Conectados
          </div>
          <!--<div class="boton-cliente" @click="dialog_cliente=true">
            <img src="../assets/ico-ventajas-w.svg" width="25"/>
            Ventajas<br>Cliente
          </div>-->
          <div class="boton-cliente" @click="dialog_cliente=true">
            <img src="../assets/ico-amigo.svg" width="25"/>
            Plan<br>Amigo
          </div>
      </div> 
<!--
      <div class="contact-info promocion">
        <b>Valida tus datos de contacto y<br> participa en el sorteo para<br> conseguir uno de estos premios:</b>
        <div style="margin: 15px">
        - Tablet Samsung Tab A7<br>
        - Smartphone Galaxy A12<br>
        - Google ChromeCast 
        </div>     
      </div>       
      <div class="contact-info legal">
        <a href="https://eurona.es/bases-legales-sorteos" target="_blanck">Bases Legales del Sorteo ></a>
      </div>
      <div class="contact-info">
        <div class="boton-mis-datos" @click="$router.push('/datas')">Ir a Mis Datos ></div>
      </div>
-->
<!--
      <div class="contact-info promocion" v-if="domain">
        <a href="https://www.eurona.es/amigo-eurona" target="_blanck"><img src="img/banner_eurona_azul.png" width="100%"></a>
      </div>  
-->
      <v-dialog v-model="dialog_actualizar" width="250">
        <v-card>
          <v-card-text>
            <div class="dialog-text-padding">
              <b>Actualiza tus datos:</b><br>
              <ul>
                <li><a href='https://eurona.es/static/images/821/cambio-de-titular-satelite.pdf' target="_blank">Cambio de titular</a></li>
                <li><a href='https://eurona.es/static/images/821/formulario-cambio-domiciliacion-bancaria.pdf' target="_blank">Cambio cuenta bancaria</a></li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue-grey" block @click="dialog_actualizar=false">cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>  

      <v-dialog v-model="dialog_servicio" width="550">
        <v-card>
          <v-card-text>
            <div class="dialog-text-padding">       
              <ul>
                <li>
                  ¿Conoces <span class="resaltado">Eurona Libertad</span>?
                  <ul>
                    <li>Consultas online con Médicos, Abogados y Veterinarios desde nuestra App móvil.</li>
                    <li>Con el Manitas Tecnológico online recibirás asesoramiento para proteger tus dispositivos <span class="resaltado">¡Incluye 3 licencias de antivirus!</span> Te ayudamos a activar el control parental. Obtén soporte para el uso de Microsoft 365 ¡y mucho más!</li>
                    <li>Tendrás incluido el Mantenimiento de tu Servicio de Internet por Satélite para tu máxima tranquilidad.</li>
                  </ul>
                </li>
                <li>
                  ¿Necesitas telefonía VOIP?
                  <ul>
                    <li>Contrata telefonía fija con numeración nueva o portabilidad.</li>
                    <li>Instalaremos un Router especial para voz en tu conexión a Internet Satelital.</li>
                    <li>o	Escoge la tarifa de voz que más se adapte a tus necesidades. + Info <a href="www.eurona.es/oferta-voz-fija">www.eurona.es/oferta-voz-fija</a></li>
                  </ul>
                </li>
                <li>
                  ¿Tienes una segunda vivienda y no tienes conexión a Internet?
                  <ul>
                    <li>Infórmate sobre nuestro producto SAT Vacaciones que te permite aumentar la velocidad cuando te encuentras en el domicilio y pagar una cuota mínima de menos de 12€ (IVA Incluido) durante el resto del año.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue-grey" block @click="dialog_servicio=false">cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>  

      <v-dialog v-model="dialog_cliente" width="750">
        <v-card>
          <v-card-text>
            <div class="dialog-text-padding">       
	
              <p style="text-align:center"><span style="font-size:20px"><span style="color:#41b6e6"><strong>¿Conoces a alguien que necesite Internet Satélite Eurona?</strong></span></span></p>

              <p style="text-align:center"><span style="color:#999999"><span style="font-size:16px">Cuantos más amigos conectes, ¡más premios te llevas!</span></span></p>

              <p style="text-align:center">&nbsp;</p>

              <p style="text-align:center"><span style="color:#41b6e6"><span style="font-size:18px"><strong>¿Quieres saber cómo?</strong></span></span></p>

              <p style="text-align:center">&nbsp;</p>

              <p style="text-align:center"><span style="color:#999999"><span style="font-size:16px">¿Quieres saber cómo? Asegúrate que usen tu código de cliente al darse de alta y recibirás hasta 800€ para gastar en Amazon.<strong> </strong></span></span></p>

              <p style="text-align:center"><span style="color:#999999"><span style="font-size:16px">1. Sólo necesitamos sus datos de contacto que nos puedes dejar </span></span><span style="font-size:16px"><a href="https://www.eurona.es/plan-amigo-eurona" target="_blank"><span style="color:#41b6e6">aquí</span></a></span><br />
              <span style="color:#999999"><span style="font-size:16px">2. Para poder saber que están recomendados por ti, necesitas tu código de cliente que encontras en tu factura.<br />
              3. Si lo prefieres, comparte con ellos esta dirección </span></span><span style="font-size:16px"><a href="https://www.eurona.es/amigo-eurona" target="_blank"><span style="color:#41b6e6">www.eurona.es/amigo-eurona</span></a></span><span style="color:#999999"><span style="font-size:16px"> para que sean ellos quienes nos dejen sus datos de contacto</span></span></p>

              <p style="text-align:center">&nbsp;</p>

              <p style="text-align:center"><span style="color:#41b6e6"><span style="font-size:18px"><strong>¡Así de fácil! </strong></span></span><br />
              <span style="color:#999999"><span style="font-size:16px">Hasta 800€ están al alcance de tu mano.</span></span></p>

              <p style="text-align:center">&nbsp;</p>

              <p style="text-align:center"><strong><span style="color:#ffffff"><span style="font-size:16px"><span style="background-color:#41b6e6">Por tu primera recomendación te regalamos 3 meses de Eurona Libertad &nbsp;<a href="https://www.eurona.es/plan-amigo-eurona" target="_blank" style="color:#999999">&lt;Aqu&iacute;&gt;</a> GRATIS.</span></span></span></strong></p>

              <p style="text-align:center">&nbsp;</p>

              <ul>
                <li><span style="color:#999999"><span style="font-size:16px">Conseguirás una <strong>tarjeta de Amazon de 30€</strong> por cada amigo que use tu código de cliente. Es decir, si alcanzas el máximo de 10 amigos, te llevarás <strong>300€ para gastar como quieras</strong>. </span></span></li>
                <li><span style="color:#999999"><span style="font-size:16px">Si superas los 10 amigos, obtendrás también <strong>3 meses gratis de Eurona Libertad</strong>, aunque ya lo tengas contratado. </span></span></li>
                <li><span style="color:#999999"><span style="font-size:16px">Y si consigues traer a 15 amigos o más, añadiremos a los premios anteriores <strong>una tarjeta de Amazon con 100€ extra</strong>. </span></span></li>
                <li><span style="color:#999999"><span style="font-size:16px">¡Aún puedes ganar un premio más! A final de año, sortearemos entre todos los participantes <strong>otra tarjeta de Amazon</strong> cuyo valor será igual a la suma de premios que el cliente haya obtenido durante toda la promoción.</span></span></li>
              </ul>

              <p>&nbsp;</p>

              <p style="text-align:center"><span style="color:#ffffff"><span style="font-size:20px"><strong><span style="background-color:#41b6e6">¡Puedes llevarte un total de hasta 800€!</span></strong></span></span></p>

              <p style="text-align:center">&nbsp;</p>

              <p style="text-align:center"><span style="color:#41b6e6"><span style="font-size:18px"><strong>Gracias por confiar en Eurona</strong></span></span></p>

              <p style="text-align:center">&nbsp;</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue-grey" block @click="dialog_cliente=false">cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </div>
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Invoive',
  data () {
    return {
      services: [],
      company: 0,
      domain: true,
      dialog_actualizar: false,
      dialog_servicio: false,
      dialog_cliente: false,
      surveys: ''
    }
  },
  mounted () {
    if (document.domain == 'eurona.pt'){
      this.domain = false
    }

    if (!this.logged) {
      this.$router.push("/")
    } else {
      this.getSurveys()
      this.getServices()
    }
  },  
  methods: {
    haveData (element, valor) {
      if (element !== undefined) if (element.includes(valor)) return true
      return false
    },
    getSurveys () {
      this.$http.get('/api/get_surveys').then(response => {
        if(response.data['result'] == 'ok') {
          this.surveys = response.data['data']
        }                
      }).catch((e) => {
        console.log(e)
      })  
    },    
    getServices () {
      this.$http.get('/api/customer_services').then(response => {
        if(response.data['result'] == 'ok') {
          this.services = []
          
          if (response.data['company'] != 0){
            // mostramos selector si hay mas de una compañia
            this.company = response.data['company']
            document.getElementById('company-content').classList.add("show")
          }

          // creamos estructura
          if (response.data['data'].length > 0) {
            for (let i = 0; response.data['data'].length > i; i++) {
              let area = this.slugify(response.data['data'][i].area)
              let existe = false
              for (let j = 0; this.services.length > j; j++) {
                if (this.services[j].area == area) {
                  this.services[j].data.push(response.data['data'][i])
                  existe = true
                  break
                }
              }
              if (existe == false) {
                this.services.push({
                  area: area,
                  name: response.data['data'][i].area,
                  data: [response.data['data'][i]]
                })
              }
            }
          }  
        } else {
          this.services = []
        }
      }).catch((e) => {
        //this.$store.commit('logout')
        //this.$router.push("/")   
        console.log(e)
      })      
    },
    selCompany () {
      let payload = {
        company: this.company
      }      
      this.$http.post('/api/set_token', payload).then(response => {
        if(response.data['result'] == 'ok') {
          this.getServices()
        }                
      }).catch((e) => {
        console.log(e)
      })  
    },     
    slugify (str) {
      return str
          .toString()
          .normalize('NFD')                   // split an accented letter in the base letter and the acent
          .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
          .replace(/\s+/g, '')
    }
  },
  computed: {
    ...mapGetters(['logged','userName', 'user'])       
  },  
  components: { LogoutMenu }
}
</script>

<style>
.user {
  font-size: 14px;
  color: var(--azul-2);
  text-align: center;
}

.ver-mas {
  cursor: pointer;
  color: var(--gris-2);
  text-decoration: underline;
}

.company-content {
    padding-bottom: 0;
}

.contact .content {
  margin-bottom: 80px;
}

.contact-info {
  margin: 0 auto;
  width: 33%;
}

.contact-info .sec-tarifa {
  padding: 0 0 15px 0;
}

.contact-info .img {
  float: left;
  width: 20%;
  height: 45px;
}

.contact-info .tarifa {
  float: left;
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  padding: 15px 0 0 0;
}

.contact-info .tarifa-area {
    clear: both;
    padding: 10px 0 0 0;
    font-size: 12px;
    color: var(--gris-1);
    font-weight: bold;
    font-style: italic;
}

.contact-info .des-corta {
  clear: both;
  font-size: 14px;
  padding: 10px 0;
}

.contact-info .children {
  padding: 0 0 0 15px;
  font-size: 13px;
}

.contact-info .children .img {
  float: left;
  width: 10%;
  height: 35px;
}

.contact-info .children .description {
  float: left;
  width: 80%;
  font-size: 13px;
  padding: 10px 0 8px 0;
  font-weight: 500;  
}

.contact-info .children .service {
  font-size: 13px;
  padding: 0 0 10px 30px;
}

.contact-info .tokens {
  padding: 0 0 0 18px;
  font-size: 13px;
  height: 40px;
}

.contact-info .tokens .img {
  float: left;
  width: 10%;
  height: 35px;
  text-align: center;
  padding-top: 5px;  
}

.contact-info .tokens .name {
  float: left;
  width: 90%;
  font-size: 13px;
  height: 25px;
  padding: 10px 0 0 0;
  font-weight: 500;  
}

.boton-mis-datos {
  margin: 15px auto;
  padding: 10px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 200px;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;
  text-align: center;
}

.te-interesa {
  color: var(--fucsia-1);
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
}

.contact .boton-servicio {
  position: relative;
  clear: both;
  float: left;
  margin: 15px auto;
  padding: 10px 0 10px 40px;
  color: var(--blanco);
  background-color: var(--azul-1);
  font-size: 15px;
  width: 140px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.contact .boton-cliente {
  position: relative;
  float: right;
  margin: 15px auto;
  padding: 10px 0 10px 25px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 15px;
  width: 145px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.boton-servicio img {
  position: absolute;
  top: 15px;
  left: 20px;
}

.boton-cliente img {
  position: absolute;
  top: 14px;
  left: 20px;
}

.resaltado {
  font-weight: bold;
  color: var(--azul-2);
}

.promocion {
  clear: both;
  width: 35%;
  padding: 15px;
  color: var(--blanco);
  background-color: var(--azul-2);
  font-size: 18px;
}

.promocion a {
  color: var(--blanco);
  font-size: 15px;
}

.logo-amazon {
    float: left;
    text-indent: -500px;
    background-position: -10px -51px;
    width: 97px;
    height: 30px;
    background-image: url(https://images-eu.ssl-images-amazon.com/images/G/30/gno/sprites/nav-sprite-global-1x-hm-dsk-reorg._CB405936353_.png);
}

.legal {
  width: 35%;  
  padding: 15px 0 15px 0;
  color: var(--gris-2);
  font-size: 12px;
  text-align: right;
}

.legal a, .legal a:visited {
  color: var(--gris-3);
}

.legal a:hover {
  color: var(--fucsia-1);
}

.facturas {
  float: left;
  margin: 15px 0;
  color: var(--azul-2);
  cursor: pointer;
}

.actualizar {
  float:right;
  margin: 15px 0;
  color: var(--azul-2);
  cursor: pointer;
}

ul {
  margin: 0 0 0 15px;
}

.link {
  color: var(--azul-2);
  cursor: pointer;
}

.surveys-productos {
  margin: 15px auto;
  padding: 15px;
  text-align: center;
  font-size: 20px;
  background-color: var(--azul-1);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  width: 25%;
  color: var(--blanco);
  font-weight: bold;
}

@media (max-width: 1024px) {
  .contact-info {
    width: 90%;
  }

  .input-select {
    width: 85%!important;
  }  

  h5 {
    clear: both;
    margin-top: 40px;
    text-align: left!important;
    width: 85%!important;  
  }  

  .boton-servicio, .boton-cliente {
    width: 125px;
  }  

  .surveys-productos {
    width: 90%;
  }  
}

</style>