<template>
  <div data-app class="consume">    
    <h1 class="titulo">
      Área de Clientes<br><br>
      <img alt="user" src="../assets/ico-consumos-b.svg" width="40"><br>
      <span class="titulo-pequeño">Consumos</span>
    </h1>
    <logout-menu></logout-menu>
    <div class="content">
      <div id="area">
        Selecciona el servicio que quieres consultar:<br><br>
        <select class="input-select" v-model="agreement" v-on:change="currentService =[]; getCurrentServices(agreement)">
          <option v-for="(item, index) in onlyServices" :key="index" :value="item.agreement_id">
            <span v-if="haveData(item.area, 'Internet')"><b> {{ item.short_description }}</b></span>   
            <span v-if="haveData(item.area, 'Movil') || haveData(item.area, 'Móvil')"><b> {{ item.phonenumber_mobile }}</b></span>
            <span v-if="haveData(item.area, 'Voz')"><b> {{ item.phonenumber_fix }}</b></span>  
          </option>
        </select>
        <br>
        <span class="address" v-if="haveData(currentService.area, 'Internet')"><b>Dirección:</b> {{ addresses[currentService.agreement_id].street }}</span>        
      </div>

      <div v-for="(item, index) in currentService.usage" :key="index" class="mov-margin">

          <!----------- Datos ----------->
          <div v-if="haveData(currentService.short_description, 'Sat')">
            <!----------- Datos Satelite ----------->
            <div class="bloque" v-if="item['usage_data'].total !== -2">
              <div class="linea-tel" v-if="currentService.phonenumber_mobile !== '' && currentService.usage[index].phonenumber !== ''"><span class="nlinea">Número de línea</span>&nbsp; <b>{{ item['phonenumber'] }}</b></div>         
              <div class="sec">
                <span class="texto">Datos</span><span class="texto"> a máxima<br>velocidad</span>
                <div class="linea" style="min-width:50%"></div>
              </div>

              <div class="dchart">
                <div class="percent" v-if="item['usage_data'].total !== -1 && rnd(((item['usage_data'].used_gb*100)/item['usage_data'].total_gb)) >= 0 ">
                  <div class="pcenter" >{{ rnd(((item['usage_data'].used_gb*100)/item['usage_data'].total_gb)) }}%</div>
                </div>
                <doughnut-chart class="doughnut" v-bind:chart="formatDatas(item['usage_data'])"></doughnut-chart>
              </div>

              <div v-if="item['usage_data'].total == -1" class="consumo-ilimitado">
                ILIMITADO
              </div>

              <div v-else class="consumos">
                <span class="quedan">Te quedan: <b>{{ (rnd(item['usage_data'].total_gb - item['usage_data'].used_gb)) > 0 ? rnd(item['usage_data'].total_gb - item['usage_data'].used_gb) : "0" }} GB</b></span><br>                
                <span class="contrato">Contratados: <b>{{ rnd(item['usage_data'].total_gb) }} GB</b></span><br>
                <span>
                  <span v-if="rnd(item['usage_data'].total_gb) > rnd(item['usage_data'].used_gb)" class="gastados">Gastados: <b>{{ rnd(item['usage_data'].used_gb) }} GB</b></span>
                  <span v-else class="gastados">Gastados: <b>{{ rnd(item['usage_data'].total_gb) }} GB</b></span>                
                </span>

              </div>
            </div>
            <!----------- Bono Datos Satelite ----------->
            <div v-if="item['usage_data'].total !== -2 && currentService.tokens != ''">
              <div class="bloque">            
                <div class="sec">
                  <span class="texto-bono">Bono Datos Extra</span>
                  <div class="linea"></div>
                  <!--<span class="precio">{{ getBonosPrice(currentService.tokens) }}€ (IVA incl.)</span>-->
                </div>

                <div class="dchart" v-if="rnd(item['usage_data'].extra_available_gb) > 0">
                  <div class="percent"><div class="pcenter" >{{ ((getBonosGb(currentService.tokens) - Math.round(item['usage_data'].extra_available_gb)) * 100) / getBonosGb(currentService.tokens) }}%</div></div>
                  <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra(((getBonosGb(currentService.tokens) - Math.round(item['usage_data'].extra_available_gb)) * 100) / getBonosGb(currentService.tokens))"></doughnut-chart>
                </div>
                <div v-else>
                  <div class="dchart" v-if="rnd(item['usage_data'].extra_available_gb) == 0">
                    <div class="percent"><div class="pcenter" >0%</div></div>
                    <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra(0)"></doughnut-chart>
                  </div>              
                  <div class="dchart" v-else>
                    <div class="percent"><div class="pcenter" >100%</div></div>
                    <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra(100)"></doughnut-chart>
                  </div>
                </div>
                <div class="consumos">
                  <span class="quedan">Te quedan: <b>{{ (rnd(item['usage_data'].extra_available_gb)) > 0 ? rnd(item['usage_data'].extra_available_gb) : (rnd(item['usage_data'].extra_available_gb)) == 0 ? getBonosGb(currentService.tokens) : "0"  }} GB</b></span><br>
                  <span class="contrato">Contratados: <b>{{ getBonosGb(currentService.tokens) }} GB</b></span><br>
                  <span class="gastados" v-if="rnd(item['usage_data'].extra_available_gb) > 0">Gastados: <b>{{ getBonosGb(currentService.tokens) - rnd(item['usage_data'].extra_available_gb) }} GB</b></span>
                  <span class="gastados" v-else>Gastados: <b>0 GB</b></span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <!----------- Datos no Satelite ----------->
            <div class="bloque" v-if="item['usage_data'].total !== -2"> 
              <div v-if="currentService.category == 'CONVERGENTE' && (item['phonenumber'][0] == 6 || item['phonenumber'][0] == 7)">
                <div class="linea-tel"><span class="nlinea">Número de línea</span>&nbsp; <b>{{ item['phonenumber'] }}</b></div>         
              </div>
              <div v-else>
                <div class="linea-tel" v-if="currentService.phonenumber_mobile !== '' && !haveData(currentService.area, 'Internet')"><span class="nlinea">Número de línea</span>&nbsp; <b>{{ item['phonenumber'] }}</b></div>         
              </div>

              <div class="sec">
                <span class="texto">Datos</span><span class="texto"> a máxima<br>velocidad</span>
                <div class="linea" style="min-width:50%"></div>
              </div>

              <div class="dchart" v-if="currentService.tokens != ''">
                <div class="percent" v-if="item['usage_data'].total !== -1 && rnd(((item['usage_data'].used_gb*100)/item['usage_data'].total_gb)) >= 0 ">
                  <div v-if="rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens) > rnd(item['usage_data'].used_gb)" class="pcenter" >{{ rnd((rnd(item['usage_data'].used_gb)*100)/(rnd(item['usage_data'].total_gb - getBonosGb(currentService.tokens)))) }}%</div>
                  <div v-else>
                    <div v-if="rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens) < rnd(item['usage_data'].used_gb)" class="pcenter" >100%</div>
                    <div v-else class="pcenter" >{{ rnd(rnd(item['usage_data'].used_gb)*100/rnd(item['usage_data'].total_gb - getBonosGb(currentService.tokens))) }}%</div>
                  </div>
                </div>
                <doughnut-chart class="doughnut" v-bind:chart="formatDatasTokens(item['usage_data'], getBonosGb(currentService.tokens))"></doughnut-chart>
              </div>
              <div class="dchart" v-else>
                <div class="percent" v-if="item['usage_data'].total !== -1 && rnd(((item['usage_data'].used_gb*100)/item['usage_data'].total_gb)) >= 0 ">
                  <div class="pcenter" >{{ rnd(((item['usage_data'].used_gb*100)/item['usage_data'].total_gb)) }}%</div>
                </div>
                <doughnut-chart class="doughnut" v-bind:chart="formatDatas(item['usage_data'])"></doughnut-chart>
              </div>

              <div v-if="item['usage_data'].total == -1" class="consumo-ilimitado">
                ILIMITADO
              </div>

              <div v-else class="consumos">
                <span class="quedan">Te quedan: <b>{{ (rnd(item['usage_data'].total_gb - getBonosGb(currentService.tokens) - item['usage_data'].used_gb)) > 0 ? rnd(item['usage_data'].total_gb - getBonosGb(currentService.tokens) - item['usage_data'].used_gb) : "0" }} GB</b></span><br>
                <span v-if="currentService.tokens != ''" class="contrato">Contratados: <b>{{ (rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens)) > 0 ? rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens) : rnd(item['usage_data'].total_gb) }} GB</b></span>
                <span v-else class="contrato">Contratados: <b>{{ rnd(item['usage_data'].total_gb) }} GB</b></span><br>
                <span v-if="currentService.tokens != ''">
                  <span v-if="rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens) > rnd(item['usage_data'].used_gb)" class="gastados">Gastados: <b>{{ rnd(item['usage_data'].used_gb) }} GB</b></span>
                  <span v-else class="gastados">Gastados: <b>{{ rnd(item['usage_data'].total_gb) - getBonosGb(currentService.tokens)}} GB</b></span>
                </span>
                <span v-else>
                  <span v-if="rnd(item['usage_data'].total_gb) > rnd(item['usage_data'].used_gb)" class="gastados">Gastados: <b>{{ rnd(item['usage_data'].used_gb) }} GB</b></span>
                  <span v-else class="gastados">Gastados: <b>{{ rnd(item['usage_data'].total_gb) }} GB</b></span>                
                </span>

              </div>
            </div>
            <!----------- Bono Datos no Satelite----------->
            <div v-if="item['usage_data'].total !== -2 && currentService.tokens != ''">
              <div class="bloque">            
                <div class="sec">
                  <span class="texto-bono">Bono Datos Extra</span>
                  <div class="linea"></div>
                  <span class="precio">{{ getBonosPrice(currentService.tokens) }}€ (IVA incl.)</span>
                </div>

                <div class="dchart" v-if="getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens)) > 0">
                  <div class="percent"><div class="pcenter" >{{ (getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens)) * 100) / getBonosGb(currentService.tokens) }}%</div></div>
                  <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra((getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens)) * 100) / getBonosGb(currentService.tokens))"></doughnut-chart>
                </div>
                <div v-else>
                  <div class="dchart" v-if="getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens)) == 0">
                    <div class="percent"><div class="pcenter" >0%</div></div>
                    <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra(0)"></doughnut-chart>
                  </div>              
                  <div class="dchart" v-else>
                    <div class="percent"><div class="pcenter" >100%</div></div>
                    <doughnut-chart class="doughnut" v-bind:chart="formatDatasExtra(100)"></doughnut-chart>
                  </div>
                </div>
                <div class="consumos">
                  <span class="quedan">Te quedan: <b>{{ getBonosGb(currentService.tokens) - getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens))}} GB</b></span><br>               
                  <span class="contrato">Contratados: <b>{{ getBonosGb(currentService.tokens) }} GB</b></span><br>
                  <span class="gastados">Gastados: <b>{{ getBonosUsed(item['usage_data'], getBonosGb(currentService.tokens)) }} GB</b></span>
                </div>
              </div>
            </div>            
          </div>

          <!----------- Llamadas ----------->
          <div class="bloque" v-if="(item['usage_voice'].total !== -2)">
            <div v-if="currentService.category == 'CONVERGENTE' && (item['phonenumber'][0] == 8 || item['phonenumber'][0] == 9)">
              <div class="linea-tel"><span class="nlinea">Línea de Voz</span>&nbsp; <b>{{ item['phonenumber'] }}</b></div>    
            </div>
            <div v-else>
              <div class="linea-tel" v-if="currentService.phonenumber_fix !== '' && currentService.category !== 'CONVERGENTE'"><span class="nlinea">Línea de Voz</span>&nbsp; <b>{{ item['phonenumber'] }}</b></div>    
            </div>
            
            <div class="sec">
              <span class="texto" v-if="haveData(currentService.area, 'Movil') || haveData(currentService.area, 'Móvil')">Llamadas<br>en Tarifa</span>
              <span class="texto" v-else>Llamadas</span><span  class="texto" v-if="currentService.phonenumber_fix != ''"><br>Nacionales</span>
              <div class="linea"></div>
            </div>

            <!-- para cuando es convergente que coja bien los minutos -->
            <div v-if="currentService.category.toLowerCase() == 'convergente'">
              <div class="dchart">
                <div v-if="item['usage_voice'].total == 0 && currentService.mobile_minutes.toLowerCase() !== 'ilimitados' && (item['phonenumber'][0] == 6 || item['phonenumber'][0] == 7)" class="percent"><div class="pcenter">0%</div></div>
                <div v-else-if="item['usage_voice'].total !== -1 && currentService.mobile_minutes.toLowerCase() !== 'ilimitados' && (item['phonenumber'][0] == 6 || item['phonenumber'][0] == 7)" class="percent"><div class="pcenter">{{ rnd((rnd(item['usage_voice'].used) * 100) / rnd(currentService.mobile_minutes)) }}%</div></div>
                <doughnut-chart v-if="item['usage_voice'].total !== -1 && currentService.mobile_minutes.toLowerCase() !== 'ilimitados' && (item['phonenumber'][0] == 6 || item['phonenumber'][0] == 7)" class="doughnut" v-bind:chart="formatCalls(item['usage_voice'])"></doughnut-chart>
                <doughnut-chart v-else class="doughnut" v-bind:chart="formatCalls(0)"></doughnut-chart>
              </div>

              <div v-if="item['usage_voice'].total == -1 || currentService.mobile_minutes.toLowerCase() == 'ilimitados' || (item['phonenumber'][0] == 9 || item['phonenumber'][0] == 8)" class="consumo-ilimitado">
                ILIMITADO
              </div>
              <div v-else class="consumos">
                <span class="quedan">Te quedan: <b>{{ (currentService.mobile_minutes - item['usage_voice'].used) > 0 ? rnd(currentService.mobile_minutes - item['usage_voice'].used) : "0" }} MIN</b></span><br>
                <span class="contrato">Contratados: <b>{{ rnd(currentService.mobile_minutes) }} MIN</b></span><br>
                <span class="gastados">Gastados: <b>{{ rnd(item['usage_voice'].used) }} MIN</b></span><br>
              </div>            
            </div>
            <div v-else>
              <div class="dchart">
                <div v-if="item['usage_voice'].total == 0 && currentService.national_minutes.toLowerCase() !== 'ilimitados'" class="percent"><div class="pcenter">0%</div></div>
                <div v-else-if="item['usage_voice'].total !== -1 && currentService.national_minutes.toLowerCase() !== 'ilimitados'" class="percent"><div class="pcenter">{{ rnd((rnd(item['usage_voice'].used) * 100) / rnd(currentService.mobile_minutes)) }}%</div></div>
                <doughnut-chart v-if="item['usage_voice'].total !== -1 && currentService.national_minutes.toLowerCase() !== 'ilimitados'" class="doughnut" v-bind:chart="formatCalls(item['usage_voice'])"></doughnut-chart>
                <doughnut-chart v-else class="doughnut" v-bind:chart="formatCalls(0)"></doughnut-chart>
              </div>

              <div v-if="item['usage_voice'].total == -1 || currentService.national_minutes.toLowerCase() == 'ilimitados'" class="consumo-ilimitado">
                ILIMITADO
              </div>
              <div v-else class="consumos">
                <span class="quedan">Te quedan: <b>{{ (currentService.mobile_minutes - item['usage_voice'].used) > 0 ? rnd(currentService.mobile_minutes - item['usage_voice'].used) : "0" }} MIN</b></span><br>
                <span class="contrato">Contratados: <b>{{ rnd(currentService.mobile_minutes) }} MIN</b></span><br>
                <span class="gastados">Gastados: <b>{{ rnd(item['usage_voice'].used) }} MIN</b></span><br>
              </div>            
            </div>            
          </div>             
            
          <div v-for="(item, index2) in currentService.children" :key="index2">
            <!-- children de voz -->
            <div class="bloque" v-if="haveData(item.area, 'Voz') && currentService.usage[index].phonenumber == ''">
              <div class="children">
                <div class="sec">
                  <span class="texto">Línea {{item['short_description']}} {{item['phonenumber_fix'].split('-')[0]}}</span>
                </div>
                <div class="nodisponible">
                  Información de consumos no disponible
                </div>
              </div>
            </div>
          </div> 

          <!----------- SMS ----------->
          <div class="bloque" v-if="(item['usage_sms'].total !== -2)">
            <div class="sec">
              <span class="texto">SMS</span>
              <div class="linea"></div>
            </div>

            <div class="dchart">
              <div v-if="item['usage_sms'].total == 0" class="percent"><div class="pcenter">0%</div></div>
              <div v-else-if="item['usage_sms'].total !== -1" class="percent"><div class="pcenter">{{ rnd((rnd(item['usage_sms'].used) * 100) / rnd(item['usage_sms'].total)) }}%</div></div>
              <doughnut-chart class="doughnut" v-bind:chart="formatSms(item['usage_sms'])"></doughnut-chart>
            </div>

            <div v-if="item['usage_sms'].total == -1" class="consumo-ilimitado">
              ILIMITADO
            </div>
            <div v-else class="consumos">
              <span class="quedan">Te quedan: <b>{{ (rnd(item['usage_sms'].total - item['usage_sms'].used)) > 0 ? rnd(item['usage_sms'].total - item['usage_sms'].used) : "0" }} SMS</b></span><br>
              <span class="contrato">Contratados: <b>{{ rnd(item['usage_sms'].total) }} SMS</b></span><br>
              <span class="gastados">Gastados: <b>{{ rnd(item['usage_sms'].used) }} SMS</b></span><br>
            </div>
          </div>

          <!----------- Fuera Tarifa ----------->
          <div class="bloque-fuera" v-if="item['phonenumber'] !== '' && (item['usage_sms'].used !== -2 || item['usage_voice'].used !== -2)">
            <div class="titulo-fuera" v-if="item['usage_sms'].used !== -2">
              Fuera de tarifa: {{ rnd((item['usage_sms'].price + item['usage_voice'].price + Number.EPSILON) * 100) / 100 }} €
            </div>                
            <div class="titulo-fuera" v-else>
              Fuera de tarifa: {{ rnd((item['usage_voice'].price + Number.EPSILON) * 100) / 100 }} €
            </div>

            <div class="texto-wrap">
              <div class="texto-fuera" v-if="item['usage_sms'].used !== -2">
                <b>SMS: {{ rnd((item['usage_sms'].price + Number.EPSILON) * 100) / 100 }} €</b><br>
                Nacionales {{ rnd((item['usage_sms'].price_national + Number.EPSILON) * 100) / 100 }} €<br>
                Internacionales {{ rnd((item['usage_sms'].price_international + Number.EPSILON) * 100) / 100 }} €<br>
                Especiales {{ rnd((item['usage_sms'].price_special + Number.EPSILON) * 100) / 100 }} €<br>
              </div>

              <div class="texto-fuera" v-if="item['usage_voice'].used !== -2">
                <b>Llamadas: {{ rnd((item['usage_voice'].price + Number.EPSILON) * 100) / 100 }} €</b><br>
                Nacionales {{ rnd((item['usage_voice'].price_national + Number.EPSILON) * 100) / 100 }} €<br>
                Internacionales {{ rnd((item['usage_voice'].price_international + Number.EPSILON) * 100) / 100 }} €<br>
                Especiales {{ rnd((item['usage_voice'].price_special + Number.EPSILON) * 100) / 100 }} €<br>
              </div>
            </div>
          </div>
      </div>

      <div class="bloque">
        <div class="boton-servicio" @click="dialog_servicio=true">
          <img src="../assets/ico-nuevo-servicio-w.svg" width="25"/>
          Nuevo<br>Servicio
        </div>
        <div class="boton-gigas" @click="dialog_gigas=true">
          <img src="../assets/ico-nuevo-servicio-w.svg" width="25"/>
          Añadir<br>Datos / Gigas
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog_servicio" width="550">
        <v-card>
          <v-card-text>
            <div class="dialog-text-padding">       
              <ul>
                <li>
                  ¿Conoces el <span class="resaltado">Kit Libertad</span>?
                  <ul>
                    <li>Consultas online con Médicos, Abogados y Veterinarios desde nuestra app móvil.</li>
                    <li>Además nos preocupamos por tu Ciberseguridad.</li>
                    <li>Te ayudamos con tus dispositivos digitales.</li>
                  </ul>
                </li>
                <li>¿Ha llegado la fibra a tu zona? <br>Te cambiamos gratis. <br> <a href="https://cobertura.eurona.es/" target="_blank">consuta cobertura</a></li>
                <li>¿Necesitas conexión a internet para tu segunda vivienda?</li>
                <li>¿Necesitas una línea móvil más?</li>
                <li>¿Quieres datos o gigas adicionales?</li>
                <br>
                Contacta con nosotros <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a> 
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue-grey" block @click="dialog_servicio=false">cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialog_gigas" width="250">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Contacta con nosotros.<br>
            <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_gigas=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import DoughnutChart from '../components/DoughnutChart.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Consume',
  data () {
    return {
      services: [],
      onlyServices: [],
      usage: '',
      phone: '',
      agreement: '',
      currentService: {},
      addresses: [],
      dialog_servicio: false,
      dialog_gigas: false
    }
  },
  mounted () {
    if (!this.logged) {
      this.$router.push("/")
    } else {
      this.getServices()
    }
  },  
  methods: {
    rnd (num) {
      return Math.round(num)
    },
    getBonosPrice (tokens) {
      let price = 0
      for (let i=0; tokens.length > i; i++) {
        price = price + tokens[i].price
      }
      return this.rnd((price + Number.EPSILON) * 100) / 100
    },
    getBonosGb (tokens) {
      let gigas = 0
      for (let i=0; tokens.length > i; i++) {
        gigas = gigas + tokens[i].extra_gb
      }
      return this.rnd(gigas)
    },    
    getBonosUsed (used_data, bonos) {
      let used_bonos = 0
      if (used_data.used_gb <= (used_data.total_gb - bonos)) {
        used_bonos = 0
      } else if (used_data.used_gb > (used_data.total_gb - bonos)) {
        used_bonos = used_data.used_gb - (used_data.total_gb - bonos)
      } else {
        used_bonos = bonos
      }
      return used_bonos
    },        
    haveData (element, valor) {
      if (element !== undefined) {
        let e = element.toLowerCase()
        if (e.includes(valor.toLowerCase())) return true
      }
      return false
    },
    getServices () {
      this.$http.get('/api/customer_services').then(response => {
        if(response.data['result'] == 'ok') {
          this.services = response.data['data']
          for (let i=0; this.services.length > i; i++) {
            if (this.services[i].category !== 'WIMAX' || this.services[i].max_velocity !== ''){
              this.onlyServices.push(this.services[i])
            }
          }
          this.currentService = this.onlyServices[0]
          if (!('usage' in this.currentService)) {
            this.getCurrentServices(this.currentService.agreement_id)
          } 
          this.agreement = this.currentService.agreement_id
          this.setAddresses()
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })      
    },
    getCurrentServices (id) {
      this.$http.get('/api/customer_current_usage/' + id).then(response => {
        if(response.data['result'] == 'ok') {
          this.currentService = response.data['data']
          this.agreement = this.currentService.agreement_id
        }
      }).catch((e) => {
        this.$store.commit('logout')
        this.$router.push("/")   
        console.log(e)
      })      
    },
    setAddresses () {
      for (let i = 0; this.onlyServices.length > i; i++) {
        this.addresses[this.onlyServices[i].agreement_id] = this.onlyServices[i].address
      }      
    },
    formatDatas (data) {
      let datas = {
        labels: ['Datos', ''],
        datasets: [{
          backgroundColor: ['#FF008C'],
          data: [0, 100]
        }]
      }
      if ( data.total_gb !== -1 ) {
        if ( this.rnd((data.used_gb*100)/data.total_gb) >= 100 ) {
          datas.datasets[0].data = [ 100 ]
        } else if ( this.rnd((data.used_gb*100)/data.total_gb) > 0 && this.rnd((data.used_gb*100)/data.total_gb) < 100 ) {        
          datas.datasets[0].data = [ this.rnd((data.used_gb*100)/data.total_gb), 100 - this.rnd((data.used_gb*100)/data.total_gb) ]
        }
      }
      return datas
    },
    formatDatasTokens (data, tokens) {
      let datas = {
        labels: ['Datos', ''],
        datasets: [{
          backgroundColor: ['#FF008C'],
          data: [0, 100]
        }]
      }
      if ( (data.total_gb - tokens) < data.used_gb ) {
        datas.datasets[0].data = [ 100 ]
      } else if ( data.used_gb == 0 ) {  
        datas.datasets[0].data = [ 0, 100 ]
      } else {
        datas.datasets[0].data = [ this.rnd((data.used_gb*100)/(data.total_gb - tokens)), 100 - this.rnd((data.used_gb*100)/(data.total_gb - tokens)) ]
      }
      
      return datas
    },    
    formatDatasExtra (data) {
      let datas = {
        labels: ['Datos', ''],
        datasets: [{
          backgroundColor: ['#FF008C'],
          data: [0, 100]
        }]
      }
      if ( data >= 100 ) {
        datas.datasets[0].data = [ 100 ]
      } else if ( data > 0 && data < 100 ) {        
        datas.datasets[0].data = [ this.rnd(data), 100 - this.rnd(data) ]
      }
      return datas
    },    
    formatCalls (call) {
      let calls = {
        labels: ['Llamadas', ''],
        datasets: [{
          backgroundColor: ['#FF008C'],
          data: [0, 100]
        }]
      }

      if  ( this.currentService.mobile_minutes.toLowerCase !== 'ilimintado' || this.currentService.mobile_minutes.toLowerCase !== '' ) {
        let percent = this.rnd((this.rnd(call.used) * 100) / this.rnd(this.currentService.mobile_minutes))
        if (percent >= 100) {
          calls.datasets[0].data = [ 100 ]
        } else if ( percent > 0 && percent < 100 ) {
          calls.datasets[0].data = [ this.rnd(percent), this.rnd(100 - percent) ]
        }
      }
      return calls
    },
    formatSms (smss) {
      let sms = {
        labels: ['SMS', ''],
        datasets: [{
          backgroundColor: ['#FF008C'],
          data: [0, 100]
        }]
      }    
      let percent = this.rnd((this.rnd(smss.used) * 100) / this.rnd(smss.total))
      if ( percent >= 100 ) {
        sms.datasets[0].data = [ 100 ]
      } else if ( percent > 0 && percent < 100 ) {
        sms.datasets[0].data = [ this.rnd(percent), 100 - this.rnd(percent) ]
      }   
      return sms
    },      
  },
  computed: {
    ...mapGetters(['logged'])
  },  
  components: { LogoutMenu, DoughnutChart }
}
</script>

<style>
#area {
  margin: 20px auto 15px auto;
  width: 40%;
  color: var(--gris-3);
}
.consume {
  margin-bottom: 50px;
}

.consume .input-select {
  margin: 0 5px 5px 0;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  padding: 5px;
  width: auto;
}

.consume .address {
  font-size: 13px;
  font-weight: 100;
}

.consume .bloque  {
  clear: both;
  margin: 20px auto 15px auto;
  width: 40%;
  height: 115px;
}

.consume .sec {
  float: left;
  margin: 15px 0 0 10px;
}

.consume .linea-tel {
  font-size: 17px;
  padding: 5px;
  background-color: var(--fucsia-1);
  margin-bottom: 10px;
  color: var(--blanco);  
}

.consume .nlinea {
  font-size: 17px;
}

.consume .texto {
  font-size: 18px;
  font-weight: 700;
}

.consume .texto-bono {
  font-size: 15px;
  font-weight: bold;
}

.consume .linea {
  margin: 5px 0;
  width: 75%;
  border: 1px solid var(--fucsia-1);
}

.consume .consumos {
  float: right;
  text-align: right;
  margin: 15px 15px 0 0;
}

.consume .nodisponible {
  clear: both;
  float: left;
  margin: 15px 15px 0 10px;
}

.consume .consumo-ilimitado {
  float: right;
  text-align: right;
  margin: 40px 15px 0 0;
}

.consume .precio {
  font-size: 15px;
}

.consume .quedan, .consume .contrato {
  font-size: 12px;
}

.consume .gastados {
  font-size: 12px;
  color: var(--fucsia-1);
}

.consume .dchart {
  position: relative;
  float: right;
  margin: auto;
  width: 100px;
  margin-bottom: 15px;
}

.consume .chartjs-render-monitor {
  height: 100px!important;
}

.consume .percent {
  position: absolute;
  top: 42%;
  width: 100%;
  font-size: 15px;
  color: var(--fucsia-1);
  font-weight: bold;
  z-index: -1;
}

.consume .pcenter {
  margin: auto;
  width: 45px;
  text-align: center;
}

.consume .doughnut {
  position: relative;
  top: 0;
  left: 0;
  z-index: -1;
}

.consume .bloque-fuera  {
  clear: both;
  margin: 0px auto 20px auto;
  width: 40%;
}

.consume .titulo-fuera {
  font-size: 15px;
  color: var(--fucsia-1);
  font-weight: 700;
  width: 65%;
  border-bottom: 2px solid var(--fucsia-1);
  padding: 0 0 5px 0px;
  margin-left: 10px;
}

.consume .texto-fuera {
  font-size: 13px;
  padding: 10px 0 0 10px;
}

.texto-wrap {
	display: flex;
	flex-direction: row;
}

.texto-fuera:first-child {
	margin-right: 15px;
}

.consume .boton-servicio {
  position: relative;
  clear: both;
  float: left;
  margin: 15px auto;
  padding: 10px 0 10px 15px;
  color: var(--blanco);
  background-color: var(--azul-1);
  font-size: 15px;
  width: 160px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.consume .boton-gigas {
  position: relative;
  float: right;
  margin: 15px auto;
  padding: 10px 0 10px 30px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 15px;
  width: 160px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.boton-servicio img {
  position: absolute;
  top: 15px;
  left: 20px;
}

.boton-gigas img {
  position: absolute;
  top: 17px;
  left: 20px;
}

.resaltado {
  font-weight: bold;
  color: var(--azul-2);
}


@media (max-width: 1024px) {
  #area {
    width: auto;
  }

  .consume .bloque, .consume .bloque-fuera  {
    width: auto;
  }

  .consume .sec {
    margin: 15px 0 0 0px;
  }

  .consume .consumos {
    margin: 15px 10px 0 0;
  }

  .consume .mov-margin {
    margin: 25px 0px;
    border-bottom: 2px solid #ff008c;
  }

  .consume .nodisponible {
    margin: 15px 15px 0 0px;
  }  
}

</style>