<template>
  <div data-app class="data">    
    <h1 class="titulo">
      Área de Clientes<br><br>
      <img alt="user" src="../assets/ico-user-b.svg" width="40"><br>
      <span class="titulo-pequeño">Tus Datos</span>
    </h1>
    <logout-menu></logout-menu>
    <div class="content">
      
      <div class="content-info">
        <div class="sec-info">
          <h2>
            Datos del titular<br>
            <span id="edit-info" class="titulo-accion" @click="editInfo()">editar</span>
            <span id="save-info" class="titulo-accion hide" @click="saveInfo()">guardar</span>
            <span id="sp-info" class="titulo-accion hide">&nbsp;|&nbsp;</span> 
            <span id="cancel-info" class="titulo-accion hide" @click="cancelInfo()">cancelar</span>
            <div class="titulo-error">{{ errorData }}</div>
          </h2>
          
          <span class="titulo-rosa">></span> <span class="titulo-peque">Documento fiscal:</span>
          <div class="data-user">{{ user.nif }}</div>
          <span class="titulo-rosa">></span> <span class="titulo-peque">Nombre y Apellios:</span>
          <div class="data-user">{{ user.name }}</div>
          <div id="boton-titular" class="hide" @click="dialog_titular=true">
            <img src="../assets/ico-user-b.svg" width="25"/>
            Cambio<br>Titular
          </div>

          <div id="info-data">
            <span class="titulo-rosa">></span> <span class="titulo-peque">Email:</span>
            <div class="data-user">{{ user.email }}</div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Telefono fijo de contacto:</span>
            <div class="data-user">{{ user.phonenumber }}</div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Telefono móvil de contacto:</span>
            <div class="data-user">{{ user.mobile }}</div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Idioma de contacto:</span>
            <div class="data-user">{{ user.language.name }}</div>
          </div>

          <div id="info-edit" class="hide">
            <span class="titulo-rosa">></span> <span class="titulo-peque">Email:</span>
            <div class="data-user">
              <input class="input-password " size="small" v-model="user.email" />
            </div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Telefono fijo de contacto:</span>
            <div class="data-user">
              <input class="input-password " size="small" v-model="user.phonenumber" />
            </div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Telefono móvil de contacto:</span>
            <div class="data-user">
              <input class="input-password " size="small" v-model="user.mobile" />
            </div>
            <span class="titulo-rosa">></span> <span class="titulo-peque">Idioma de contacto:</span>
            <div class="data-user">
              <div class="data-user">{{ user.language.name }}</div>
            </div>
         
          </div>
          <input class="input-password " size="small" v-model="user.language.code" hidden/>
<!--
          <div id="boton-validar-mis-datos" class="boton-validar-mis-datos" @click="sendInfo()">Validar Mis Datos ></div>
          <div id="texto-validar-mis-datos" class="texto-validar-mis-datos hide">
            <b>!Gracias por confirmar tus datos!</b><br>
            Se ha añadido tu participación en el Sorteo
          </div>
-->
          <div class ="surveys" v-if="surveys != ''">
            <div class="titulo">{{ surveys.name }}</div>
            <v-divider style="margin: 15px 0px;"></v-divider>
            {{ surveys.description }}<br><br>
            Para participar pulsa <a :href="surveys.url" target="_blank">aquí</a>
          </div>
        </div>

        <div class="sec-info">
          <h2>Datos de facturación<br>
            <span class="titulo-accion" @click="true"><br></span>
          </h2>
          <span class="titulo-rosa">> </span><span class="titulo-peque">Número de cuenta:</span>
          <div class="data-user"> **** **** **** {{ user.bank }}</div> 
          <div class="boton-facturacion" @click="dialog_contact=true">
            <img src="../assets/ico-cambiar-cuenta-w.svg" width="25"/>
            Cambio<br>Cuenta
          </div>         
        </div>

        <div class="sec-info">
          <h2>
            Datos de acceso<br>
            <span id="edit-access" class="titulo-accion" @click="editAccess()">editar</span> 
            <span id="save-access" class="titulo-accion hide" @click="saveAccess()">guardar</span>
            <span id="sp-access" class="titulo-accion hide">&nbsp;|&nbsp;</span> 
            <span id="cancel-access" class="titulo-accion hide" @click="cancelAccess()">cancelar</span> 
            <div class="titulo-error">{{ errorPass }}</div>           
          </h2>
          
          <span class="titulo-rosa">> </span><span class="titulo-peque">Usuario</span>
          <div class="data-user">{{ user.username }}</div>
          <div id="old-pass">
            <span class="titulo-rosa">> </span><span class="titulo-peque">Contraseña actual:</span>
            <div class="data-user">
              <input id="input-act-password-d" class="input-password" type="password" placeholder="************" size="small" disabled/>            
            </div>
          </div>
          <div id="new-pass" class="hide">
            <span class="titulo-rosa">> </span><span class="titulo-peque">Contraseña actual:</span>
            <div class="data-user">              
              <input id="input-act-password" class="input-password " type="password" name="act-password" placeholder="Contraseña Actual" size="small" v-model="actPassword" />
            </div>            
            <span class="titulo-rosa">> </span><span class="titulo-peque">Nueva contraseña:</span>
            <div class="data-user">
              <input id="input-password" class="input-password" type="password" name="password" placeholder="Nueva contraseña" size="small" v-model="password" />
            </div>
            <span class="titulo-rosa">> </span><span class="titulo-peque">Confirma nueva contraseña:</span>
            <div class="data-user">
              <input id="input-re-password" class="input-password" type="password" name="re-password" placeholder="Repite la nueva contraseña" size="small" v-model="rePassword" />
            </div>
            <div class="txt-pass">La contraseña deberá tener 8 dígitos y combinar como mínimo números, mayusculas y minúsculas</div>
          </div>                              
        </div>

      </div>
    </div>

    <v-dialog v-model="dialog_titular" width="250">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Descarga <a href='https://eurona.es/static/images/821/cambio-de-titular-satelite.pdf' target="_blank">aquí</a> tu formulario,
            una vez cumplimentado por el titular del servicio, deberás enviar la documentación a
            <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_titular=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

    <v-dialog v-model="dialog_contact" width="250">
      <v-card>
        <v-card-text class="dialog-text-padding">
          <div class="dialog-text-padding">
            Descarga <a href='https://eurona.es/static/images/821/formulario-cambio-domiciliacion-bancaria.pdf' target="_blank">aquí</a> tu formulario,
            una vez cumplimentado por el titular del servicio, deberás enviar la documentación a
            <a href="mailto:atencionalcliente@eurona.com">atencionalcliente@eurona.com</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey" block @click="dialog_contact=false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Data',
  data () {
    return {
      errorData: '',
      errorPass: '',
      password: '',
      rePassword: '',
      actPassword: '',
      dialog_contact: false,
      dialog_titular: false,
      surveys: ''
    }
  },
  mounted () {
    if (!this.logged) {
      this.$router.push("/")
    } else {
      this.getSurveys()
    }
  },  
  methods: {
    getSurveys () {
      this.$http.get('/api/get_surveys').then(response => {
        if(response.data['result'] == 'ok') {
          this.surveys = response.data['data']
        }                
      }).catch((e) => {
        console.log(e)
      })  
    },
    editInfo () {
      this.errorData = ''
      document.getElementById("info-data").classList.add("hide")
      document.getElementById("boton-titular").classList.remove("hide")
      document.getElementById("info-edit").classList.remove("hide")
      document.getElementById("edit-info").classList.add("hide")
      document.getElementById("cancel-info").classList.remove("hide")  
      document.getElementById("sp-info").classList.remove("hide")  
      document.getElementById("save-info").classList.remove("hide")      
    },
    cancelInfo () {
      this.errorData = ''
      document.getElementById("boton-titular").classList.add("hide")
      document.getElementById("info-edit").classList.add("hide")
      document.getElementById("info-data").classList.remove("hide")
      document.getElementById("save-info").classList.add("hide")
      document.getElementById("cancel-info").classList.add("hide")  
      document.getElementById("sp-info").classList.add("hide")              
      document.getElementById("edit-info").classList.remove("hide")  
    },
    validateInfo () {
      if (this.user.email == '' && (this.user.phonenumber == '' || this.user.mobile == '')) {
        this.errorData = 'Faltan campos por rellenar'
        return false
      }
      if (this.user.email !== '') {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regex.test(this.user.email.toLowerCase())) {
          this.errorData = 'Email incorrecto'
          return false          
        }
      }      
      if (this.user.phonenumber !== '') {
        if (!(/^\d+$/.test(this.user.phonenumber)) || this.user.phonenumber.length!=9  || (this.user.phonenumber.substr(0, 1)!='8' && this.user.phonenumber.substr(0, 1)!='9')){
          this.errorData = 'Teléfono fijo incorrecto'
          return false
        }
      }
      if (this.user.mobile !== '') {
        if (!(/^\d+$/.test(this.user.mobile)) || this.user.mobile.length!=9  || (this.user.mobile.substr(0, 1)!='6' && this.user.mobile.substr(0, 1)!='7')){
          this.errorData = 'Teléfono móvil incorrecto'
          return false
        }
      }
      return true
    },
    saveInfo () {
      if (this.validateInfo()) {
        this.errorData = 'Enviando datos ...'     
        let payload = {
          email: this.user.email,
          mobile: this.user.mobile,        
          phonenumber: this.user.phonenumber,
          language: this.user.language.code
        }
        this.$http.post('/api/customer_update_info', payload).then(response => {
          if(response.data['result'] == 'ok') {
            this.cancelInfo()
            this.errorData = 'Tus datos se han actualizado con éxito'
          } else {
            this.errorData = 'Datos erroneos'
          }
        }).catch(() => {
          this.$store.commit('logout')
          this.$router.push("/")   
        })     
      }
    },    
    editAccess () {
      document.getElementById("edit-access").classList.add("hide")
      document.getElementById("save-access").classList.remove("hide")
      document.getElementById("sp-access").classList.remove("hide")
      document.getElementById("cancel-access").classList.remove("hide")
      document.getElementById("old-pass").classList.add("hide")      
      document.getElementById("new-pass").classList.remove("hide")

    },
    cancelAccess () {
      this.errorPass = ''
      this.actPassword = ''
      this.password = ''
      this.rePassword = ''
      document.getElementById("save-access").classList.add("hide")
      document.getElementById("sp-access").classList.add("hide")
      document.getElementById("cancel-access").classList.add("hide")
      document.getElementById("edit-access").classList.remove("hide")
      document.getElementById("new-pass").classList.add("hide")      
      document.getElementById("old-pass").classList.remove("hide")
    },
    saveAccess () {
      if (this.validatePassword()) {
        this.errorPass = 'Enviando datos ...'     
        let payload = {
          password: this.password,
          current_password: this.actPassword,          
        }
        this.$http.post('/api/reset_password', payload).then(response => {
          if(response.data['result'] == 'ok') {
            if(response.data['data']['updated'] == true) {
              this.cancelAccess()
              this.errorPass = 'Tus datos se han actualizado con éxito'
            } else {
              this.errorPass =  this.getError(response.data['data'])
            }
          } else {
            this.errorPass =  this.getError(response.data['data']) 
          }
        }).catch(() => {
          this.$store.commit('logout')
          this.$router.push("/")   
        })
      }
    },
    validatePassword () {
      if (this.actPassword == '' || this.password == '' || this.rePassword == '') {
        this.errorPass = 'Faltan campos por rellenar'
        return false
      } else if (this.password !== this.rePassword) {
        this.errorPass = 'Las contraseñas no coinciden'
        return false
      } else if (this.password.length < 8 || this.password.search(/[A-Z]/) == -1 || this.password.search(/[a-z]/) == -1) {
        this.errorPass = 'Nueva contraseña no válida'        
        return false        
      }
      this.errorPass = ''
      return true
    },
    getError (error) {
      if (error == 'Invalid token') return 'Sesión caducada. Inicie sesión de nuevo'
      if (error == 'Invalid password format') return 'Nueva contraseña incorrecta'
      if (error == 'Invalid Password') return 'Contraseña incorrecta'
      return 'No se ha podido cambiar la contraseña'
    },
    sendInfo () {
      let payload = {
        document: this.user.nif,
        data: {'type': 'validation'}
      }      
      this.$http.post('/api/info_clients', payload).then(response => {
        if(response.data['result'] == 'ok') {
          document.getElementById("boton-validar-mis-datos").classList.add("hide")
          document.getElementById("texto-validar-mis-datos").classList.remove("hide")
          console.log(response.data)
        }                
      }).catch((e) => {
        console.log(e)
      })  
    },  
    getInfo (type) {
      let payload = {
        document: this.user.nif,
        type: type
      }      
      this.$http.get('/api/is_client', {'params':payload}).then(response => {
        if(response.data['result'] == 'ok') {
          console.log(response.data)
        }                
      }).catch((e) => {
        console.log(e)
      })  
    }    
  },
  computed: {
    ...mapGetters(['logged', 'user'])
  },
  components: { LogoutMenu }
}
</script>

<style>
.content-info {
  padding: 15px;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .content-info {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
  }
}

.titulo-error {
  margin-top: 5px;
  color: var(--fucsia-1);
  font-size: 12px;
  font-weight: bold;
}

.sec-info h2 {
  font-size: 18px;
}

.sec-info .titulo-accion {
  color: var(--fucsia-1);
  font-size: 12px;
  font-weight: 100;
  cursor: pointer;
}

.sec-info .titulo-rosa {
  color: var(--fucsia-1);
  font-size: 12px;
  font-weight: bold;
}

.sec-info .titulo-peque {
  color: var(--gris-1);
  font-size: 12px;
  font-weight: bold;
}

.sec-info .data-user {
  padding: 5px 0 5px 10px;
  font-size: 14px;
  color: var(--gris-3);
}

.sec-info .input-password {
  width: 80%;
  margin: 0 auto;
  height: 15px;
  font-size: 13px;
  font-weight: 400;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  color: var(--gris-3);
  padding: 8px;
}

.sec-info .txt-pass {
  padding: 5px 0;
  font-size: 12px;
  width: 90%;  
}  

.boton-validar-mis-datos {
  margin: 15px;
  padding: 10px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 200px;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;
  text-align: center;
}

.texto-validar-mis-datos {
  margin: 15px 30px 0 0;
  padding: 15px;
  color: var(--blanco);
  background-color: var(--azul-2);
  font-size: 13px;
}

.texto-validar-mis-datos b {
  font-size: 16px;
}

#boton-titular {
  position: relative;
  margin: 5px 0 15px 30px;
  padding: 3px 0 3px 15px;
  color: var(--blanco);
  background-color: var(--azul-1);
  font-size: 15px;
  width: 140px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

#boton-titular img {
  position: absolute;
  top: 7px;
  left: 20px;
}

.boton-facturacion {
  position: relative;
  margin: 5px 0 15px 0px;
  padding: 3px 0 3px 15px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 15px;
  width: 140px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.boton-facturacion img {
  position: absolute;
  top: 7px;
  left: 20px;
}

.surveys {
  margin: 15px;
  padding: 15px;
  text-align: center;
  font-size: 15px;
  background-color: var(--azul-1);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  color: var(--blanco);
  font-weight: bold;
}

.surveys .titulo {
  font-size: 20px;
  border: 0px;
  box-shadow: none;
  margin: 5px 0 0 0;
  padding: 0px;
  color: var(--blanco);
}

.surveys a {
    color: var(--azul-3);
}
</style>