<template>
  <div class="search">    
    <h1 class="titulo">Área de Clientes - <span class="titulo-pequeño">Preguntas Frecuentes</span></h1>
    <logout-menu></logout-menu>
    <div class="content">
      <div class="faq">
        <div class="title">
          Preguntas Frecuentes
        </div>

        <div>
          <div class="section" v-for="section in sections" :key="section.title">
            <div class="title" @click="toggleSection(section)">
              <div class="arrow" :class="section.toggle ? 'rotate90' : ''">
                <span class="pink-title">></span>
              </div> {{ section.title }}
            </div>
            <transition name="list">
              <div class="section-container" v-if="section.toggle">
                <div class="question-container" v-for="(content, qindex) in section.content" :key="'question-' + qindex">
                  <div class="question" @click="toggleQuestion(content)">
                    <div class="arrow" :class="content.toggle ? 'rotate90' : ''">
                      <span class="pink-title">></span>
                    </div> 
                    {{ content.question }}
                  </div>
                  <transition name="list">
                    <div class="answer" v-if="content.toggle">
                      <div>
                        {{ content.answer }}
                      </div>

                      <div v-if="content.additional_answers !== undefined">
                        <div v-for="(answer, aindex) in content.additional_answers" :key="'answer-' + qindex + '-' + aindex">
                          <div class="superior-answer">
                            {{ answer.superior }}
                          </div>

                          <div class="inferior-answer" v-for="(inferior, iindex) in answer.inferior" :key="'answer-' + qindex + '-' + aindex + '-' + iindex">
                            {{ inferior }}
                          </div>
                        </div>
                      </div>

                      <div v-if="content.additional_content !== undefined" class="image-container">
                        <img :src="require('@/assets/' + content.additional_content)"/>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoutMenu from '../components/LogoutMenu.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Search',
  data () {
    return {
      sections: [
        {
          title: 'Factura',
          content: [
            {
              question: '¿Cómo me facturan? ¿Qué ciclo de facturación tengo?',
              answer: 'R. Facturamos en meses naturales, esto es del 1 al 30 de cada mes. Recuerda que nuestra facturación es por adelantado, por tanto, al principio de cada mes pagas la factura del mes en curso.'
            }, {
              question: '¿En fecha será el cargo el cargo al banco?',
              answer: 'R. Pasaremos el cargo a tu cuenta bancaria en los primeros 5 días del mes.'
            }, {
              question: '¿se puede cambiar la fecha de cargo al banco?',
              answer: 'R. Solo tenemos un ciclo de facturación, por lo que no podemos cambiar las fechas de cargo al banco.'
            }, {
              question: '¿Cómo recibiré mi factura?',
              answer: 'R. Recibirás tu factura por correo electrónico a principios de cada mes, antes de que recibas el cargo en el banco. También estan disponibles en el apartado Facturas del Área Cliente.'
            }, {
              question: '¿Cómo puedo pagar una factura devuelta?',
              answer: 'R. Nos puedes llamar al 910780088 para realizar el pago o realizar una transferencia al número de cuenta que te indicamos en el mensaje donde te informamos del importe de la factura pendiente En todos los casos debes usar la referencia de pago que te enviamos por correo electrónico. Si pagas por transferencia necesitaremos 24/48 horas para localizar el pago, en el resto de los métodos de pago se reconocerá automáticamente. '
            }
          ]
        }, {
          title: 'Información general',
          content: [
            {
              question: '¿Con que tecnologías trabajamos?',
              answer: 'R. Somos una compañía que llega donde otros no llegan usando tecnologías de satélite, 4G, Wimax y Fibra. Aplicamos la tecnología que mejor se adapta a la situación de cada cliente.'
            }
          ]
        }, {
          title: 'Productos y Servicios',
          content: [
            {
              question: '¿Como puedo desviar las llamadas de mi móvil a mi buzón de voz?',
              answer: 'R. En función de las llamadas que quieras desviar, deberás usar uno de los siguientes códigos:',
              additional_answers: [
                {
                  superior: '•	Para todas las llamadas: Con esto hacemos que TODAS las llamadas vayan a parar al buzón de voz.',
                  inferior: [
                    'o	Se activa con: **21*242#',
                    'o	Se desactiva con: ##21#'
                  ]
                }, {
                  superior: '•	Para cuando estemos sin cobertura o con el teléfono apagado: Útil si apagamos el móvil, se queda sin batería o estamos en modo avión.',
                  inferior: [
                    'o	Se activa con: **62*242#',
                    'o	Se desactiva con: ##62#'
                  ]
                }, {
                  superior: '•	Para cuando la línea está ocupada: Si estamos hablando y no queremos perder llamadas.',
                  inferior: [
                    'o	Se activa con: **67*242#',
                    'o	Se desactiva con: ##67#'
                  ]
                }, {
                  superior: '•	En el caso de que no conteste: Útil si dejamos el móvil o no nos da tiempo a contestar una llamada',
                  inferior: [
                    'o	Se activa con: **61*242#',
                    'o	Se desactiva con: ##61#'
                  ]
                }, {
                  superior: '•	Desactivar cualquier desvío: Con esto cancelamos cualquier tipo de desvío que tengamos activado, incluidos desvíos a otros números que no sean el contestador.',
                  inferior: [
                    'o	El código es ##002#'
                  ]
                }
              ]
            }, {
              question: '¿Cómo ver que productos tengo contratados?',
              answer: 'R. Puedes ver los productos que tienes contratados en la sección Mis Productos del Área de Cliente. Además los productos que tienes contratados aparecen reflejados en la factura. Tienes una línea con tu conexión a internet, en ella puedes ver si tu conexión es satélite, que aparecerá con la descripción “Sat”. 4G, que tendrá “Internet en casa 4G”, “Wimax” o “fibra”. También verás el resto de los productos que tengas contratados, como la línea fija, el móvil.'
            }, {
              question: '¿Cómo puedo solicitar una modificación en mi servicio?',
              answer: 'R. Puedes modificar los servicios que tienes contratados en nuestro servicio de Atención al Cliente, llamando al 910780088 o enviando un mail a atencionalcliente@eurona.com'
            }, {
              question: '¿Qué volumen de datos tengo contratado y que puedo hacer con ellos?',
              answer: 'R. Cada producto tiene un volumen de datos mensual en función de la tarifa contratada, que puede ser de 40GB, 80GB, 100GB o 200GB. En nuestra web (Eurona.com) podrás ver el volumen que corresponde a tu producto, si tienes dudas sobre tus productos consulta el apartado 9. A continuación se muestran ejemplos de usos asociados a cada volumen de datos. Hay productos como la conexión de fibra que no tiene tienen limitación de datos de descarga.',
              additional_content: ['internet-examples.png']
            }, {
              question: '¿Cómo puedo saber el consumo de datos del mes actual?',
              answer: 'R. Enviaremos un mensaje a tu teléfono móvil cuando hayas alcanzado el 70%, 90% y el 100% de tu capacidad (si tu servicio es satélital, de momento no esta información no está disponible para el resto de servicios). Además, dentro de poco podrás consultar esta información también des el Área de Cliente.'
            }, {
              question: '¿Cómo puedo ampliar la capacidad de GB que tengo contratada?',
              answer: 'R. Puedes contratar bonos adicionales siempre que los necesites llamándonos a nuestro servicio de atención al cliente (910780088), también puedes cambiar a una tarifa que se ajuste mejor a tus necesidades de uso.'
            }, {
              question: '¿Las soluciones de internet incluyen mantenimiento?',
              answer: 'R. En el caso de internet satélite disponemos de soluciones a medida que incluyen este servicio. Puedes consultar las opciones en la web Eurona.com.'
            }, {
              question: '¿Puedo tener telefonía fija con mi servicio?',
              answer: 'R. Si, puedes contratar la telefonía fija junto con tu conexión a internet. No puedes contratar el fijo como un producto independiente.'
            }, {
              question: '¿Puedo contratar una línea móvil?',
              answer: 'R. Si, puedes añadir líneas móviles a tu conexión de internet, sea cual sea tu tecnología. No se puede contratar el móvil como un producto independiente.'
            }, {
              question: 'Para realizar mi portabilidad a Eurona, ¿cómo puedo conocer mi número ICCID de mi SIM?',
              answer: 'R. El mejor método para conocer mi número ICCID es utilizar alguno de estos dos métodos, dependiendo de si mi teléfono es Android o Apple:',
              additional_answers: [
                {
                  superior: '•	Android: Descárgate la app “ICC ID” en Google Play',
                }, {
                  superior: '•	Apple: Ve a Ajustes > General > Información > Número ICCID.',
                }
              ]
            }, {
              question: '¿Cómo puedo darme de baja?',
              answer: 'R. No queremos que te vayas, pero si quieres la baja de tu servicio, debes ponerte en contacto con nuestro servicio de Atención al Cliente llamando al 910780088.'
            }, {
              question: '¿Cómo puedo hacer un cambio de titularidad?',
              answer: 'R. Para realizar un cambio de titularidad necesitamos que el antiguo y el nuevo titular, aporten documentación identificativa y firmen y cumplimenten el formulario del cambio de titularidad. Desde nuestro servicio de Atención al Cliente te enviaremos el formulario necesario llamando al 91780088 o enviando un mail a atencionalcliente@eurona.com. Este cambio puede tener costes de gestión.'
            }, {
              question: '¿Cómo puedo modificar mis datos de contacto?',
              answer: 'R. Tenemos como datos de contacto el teléfono y el mail que nos dejaste al firmar el contrato, si han cambiado llámanos al 910780088 para que podamos actualizarlos.'
            }, {
              question: '¿Cómo puedo ver el consumo de mi teléfono móvil?',
              answer: 'R. Puedes consultarlo en esta dirección (https://clientesmovil.eurona.es/login). Tu usuario será el teléfono móvil y la contraseña tu DNI/NIE/CIF.'
            }
          ]
        }, {
          title: 'Consultas técnicas',
          content: [
            {
              question: '¿Funciona mi conexión para ver TV online (Nexflix, HBO, Youtube…)?',
              answer: 'R. Si, puedes ver TV online con tu conexión en calidad básica sin problema.'
            }, {
              question: '¿Funciona mi conexión para juegos online?',
              answer: 'R. Si tu conexión es “Internet en casa 4G” o “fibra” podrás jugar online. Las conexiones por satélite no son adecuadas para este tipo de uso porque la conexión entre tu ordenador/consola y el servidor tiene un retraso de entorno a medio segundo, ten en cuenta que los datos tienen que viajar hasta el satélite y volver (aproximadamente 50.000 km), lo que no lo hace adecuado para juegos en tiempo real. Si tienes dudas sobre como ver que producto tienes contratado consulta el apartado 9'
            }, {
              question: '¿Es posible montar una VPN?',
              answer: 'R. La tecnología satélite es incompatible para el uso de juegos online en tiempo real y las VPN, ya que se ven afectados por la latencia.'
            }, {
              question: '¿Puedo contratar un servicio de IP Fija?',
              answer: 'R. En el caso de internet satélite disponemos de soluciones a medida que incluyen este servicio. Puedes consultar las opciones en la web Eurona.com  '
            }, {
              question: '¿A qué velocidad puedo navegar con mi conexión?',
              answer: 'R. Los productos que tienes contratados aparecen reflejados en la factura. Tienes una línea con tu conexión a internet donde además del tipo de producto podrás ver en tú contrato la velocidad de conexión priorizada que tienes contratada. Ten en cuenta que esta es una velocidad se puede ver afectada por diferentes efectos como el número de dispositivos que tengas conectados a la vez o si usas una conexión Wifi o por cable'
            }, {
              question: '¿Si supero mi bono de datos puedo seguir haciendo llamadas de voz?',
              answer: 'R.  Si, si tienes contratada voz fija seguirá funcionando, aunque hayas superado el volumen de datos que tengas contratado.'
            }, {
              question: '¿Mi conexión no funciona? ¿Qué puedo hacer?',
              answer: 'R.  Si tienes problemas con la conexión prueba a seguir estos pasos:',
              additional_answers: [
                {
                  superior: '-	Si la conexión es más lenta de lo habitual asegúrate de que nos has consumido el volumen de datos que tienes con la velocidad máxima contratada., porque a partir de ese momento la velocidad de conexión se reduce. Deberías haber recibido un SMS informándote de que has superado este volumen de datos.',
                }, {
                  superior: '-	Asegura que los equipos están correctamente conectados a la red electrica.',
                }, {
                  superior: '-	Reinicia los equipos (modem y/o router) para solucionar un posible bloqueo.',
                }, {
                  superior: 'Si después de estos pasos continúas sin recuperar la conexión, llámanos al 910780088 y selecciona la opción “soporte técnico”.',
                }
              ]
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (!this.logged) {
      this.$router.push("/")
    }
  },  
  methods: {
    toggleSection (section) {
      this.$set(section, 'toggle', section.toggle === null ? true : !section.toggle)
    },
    toggleQuestion (content) {
      this.$set(content, 'toggle', content.toggle === null ? true : !content.toggle)
    }
  },
  computed: {
    ...mapGetters(['logged'])
  },  
  components: { LogoutMenu }
}
</script>

<style scoped>
  .faq {
    padding: 0px 30px;
  }

  .section {
    margin: 0 auto;
    padding: 10px;
  }

  .title {
    float: left;
    margin: 0 auto;
    margin-top: 15px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
  }

  .question-container {
    clear: both;
    border: 0.5px solid pink;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
    height: max-content;
  }

  .question {
    font-size: 18px;
    cursor: pointer;
  }

  .section-container, .answer {
    padding: 5px 10px 0 10px;
    overflow: hidden;
    height: max-content;
  }

  .superior-answer {
    padding-top: 10px;
    padding-left: 20px;
  }

  .inferior-answer {
    padding-left: 40px;
  }

  .pink-title {
    color: var(--fucsia-1);
    font-size: 12px;
    font-weight: bold;
  }

  .arrow {
    float: left;
    width: 20px;
    height: 20px;
    text-align: center;
  }

  .rotate90 {
    transform: translateY(-100%) rotate(90deg);
    transform-origin: bottom left;
  }

  .image-container {
    float: left;
    text-align: center;
    width: 100%;
  }

  .image-container > img {
    margin: 0 auto;
    max-width: 1024px;
  }

  .list-enter,
  .list-leave-to {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.3s;
  }

  @media (max-width: 768px) {
    .image-container > img {
      max-width: 100%;
    }
    .faq {
      padding: 0px;
      margin-bottom: 50px;
    }    
  }
</style>