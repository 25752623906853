import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Password from '../views/Password.vue'
import NewPassword from '../views/NewPassword.vue'
import Invoice from '../views/Invoice.vue'
import Contract from '../views/Contract.vue'
import Consume from '../views/Consume.vue'
import Data from '../views/Data.vue'
import Search from '../views/Search.vue'
import Help from '../views/Help.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/password',
    name: 'Password',
    component: Password
  },
  {
    path: '/newpassword/:saleforceid/:token',
    name: 'Nuevo Password',
    component: NewPassword,
    props: true
  },
  {
    path: '/invoices',
    name: 'Facturas',
    component: Invoice
  },
  {
    path: '/contracts',
    name: 'Contrato',
    component: Contract
  },
  {
    path: '/consumes',
    name: 'Consume',
    component: Consume
  },
  {
    path: '/datas',
    name: 'Datos',
    component: Data
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
