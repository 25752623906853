<template>
  <div class="password">
      <h1 v-if="this.$route.query.type==0" class="titulo">CREAR CUENTA</h1>
      <h1 v-else class="titulo">RECUPERAR CONTRASEÑA</h1>
      <div class="password-content">
        <h5 id="titulo-password">NIF o NIE del titular</h5>
        <input id="input-password" class="input-password" name="dni" placeholder="Usuario" size="small" v-model="dni" />
        <br>
        <div  v-if="this.$route.query.type==0" id="texto-password0" class="texto-password">Vamos a enviarte un email para verificar tus datos y crear tu contraseña con seguridad</div>
        <div  v-else id="texto-password1" class="texto-password">Vamos a enviarte un email para verificar tus datos y recuperar tu contraseña con seguridad</div>
        <div id="error-password" class="error-password">{{ error }}</div>
        <div id="boton-password" class="boton-password" @click="send()">Enviar</div>
        <div id="boton-access" class="boton-access" @click="access()">Iniciar sesión</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'password',
  data () {
    return {
      dni: '',
      error: ''
    }
  },
  methods: {
    send () {
      if (this.validateDocument()) {
        this.error = 'Enviando datos...'
        document.getElementById("boton-password").classList.add("hide");
        let payload = { username: this.dni }
        this.$http.post('/api/customer_email', payload).then(response => {          
          if(response.data['result'] == 'ok') {
            this.error = ''
            document.getElementById("titulo-password").remove();
            document.getElementById("input-password").remove();
            if (this.$route.query.type == 0) {
              document.getElementById("texto-password0").innerHTML="Si el usuario está registrado en nuestro servicio tendrás un correo para recuperar tu contraseña. Por favor revisa tu bandeja de entrada. Si no lo localizas no olvides revisar también tu carpeta de notificaciones y de spam.";
            } else {
              document.getElementById("texto-password1").innerHTML="Si el usuario está registrado en nuestro servicio tendrás un correo para recuperar tu contraseña. Por favor revisa tu bandeja de entrada. Si no lo localizas no olvides revisar también tu carpeta de notificaciones y de spam.";
            }
            document.getElementById("boton-password").remove();
            document.getElementById("boton-access").classList.add("show");
          } else {
            document.getElementById("boton-password").classList.remove("hide");
            this.error = 'No se ha podido enviar el email'
          }
        }).catch(() => {
          document.getElementById("boton-password").classList.remove("hide");
          this.error = 'Usuario incorrecto'
        })
      }
    },
    validateDocument () {
      if (this.dni == ''){
        this.error = 'Debes introducir un documento válido'
        return false
      }
      this.error = ''
      return true
    },    
    access () {
      this.$router.push("/")
    }
  },
}
</script>

<style>
.titulo-acceso {
  margin: 50px 0 15px 0;
  text-align: center;
}

.password-content {
  text-align: center;
}

#ico-password-ok {
  margin: auto;
}

h5 {
    text-align: left;
    width: 21%;
    margin: 15px auto 0 auto;
    font-weight: normal;
    font-size: 16px;
}

.input-password {
  width: 85%;
  margin: 5px auto !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  color: #BABABA;
  padding: 8px;
}

.texto-password {
  margin: 5px auto;
  text-align: left!important;
  font-size: 16px;
  width: 90%;
  cursor: pointer;
}

.error-password {
  margin: 50px auto 0 auto;
  text-align: center;
  color: var(--fucsia-1);
  font-size: 18px;
  width: 90%;
}

.boton-password {
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;  
}

.boton-access {
  display: none;
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;    
}

@media (min-width: 768px) {
  .password-content .input-password, 
  .password-content .texto-password, 
  .password-content .boton-password, 
  .password-content .boton-access {
    width: 20%;
  }
}

@media (max-width: 768px) {
  h5 {
    width: 90%;
  }
}
</style>