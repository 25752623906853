<script>
import { Bar } from 'vue-chartjs'
export default {
  extends: Bar,
  props: ['year'],
  data: () => ({
    invoices: {
      count: 0,
      data: []
    },
    invoice: {
      count: 0,
      data: []
    },
    chartdata: {
      labels: [],
      datasets: [
        {
          label: 'Facturas',
          backgroundColor: '#FF008C',
          data: [],
          categoryPercentage: 1.0,
          barPercentage: 0.5,
        }
      ]
    },
    options: {
      tooltips: {
        enabled: true,
        displayColors: false
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxes: [{   
          offset: true,
          gridLines: {
            display:false,
          },
          ticks: {
            fontSize: 11,
            maxRotation: 0,
            minRotation: 0,
            autoSkip: true,
          },
        }],
        yAxes: [{
          gridLines: {
            display:false,
          },
          ticks: {
            display:false,
            beginAtZero: true
          }          
        }]
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
      },
      legend: {
        display: false,
      }      
    }
  }),
  mounted () {
    this.getInvoices()    
  },
  methods: {
    getInvoices () {
      let _this = this
      this.$http.get('/api/customer_invoices').then(response => {
        if(response.data['result'] == 'ok') {
          this.invoices = response.data['data']
          for (let i=0; this.invoices.count > i; i++) {
            if (this.invoices.data[i].date != null) {
              this.invoice.count = this.invoice.count + 1
              this.invoice.data.push(this.invoices.data[i])
            }
          }
          this.invoices = this.invoice
          this.invoices.data.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
          let currentYear = new Date().getFullYear()
          this.selectYear(currentYear)
          this.$refs.canvas.onclick = function(evt) {
            let activePoints = _this.$data._chart.getElementsAtEvent(evt);
            if (activePoints[0]) {
              let idx = activePoints[0]['_index']
              let label = _this.chartdata.labels[idx]
              let dates = label[1].split('-')
              _this.$parent.updateMonth(dates[0])
            }
          }
        }
      }).catch((e) => {
        console.log(e)
      })      
      this.options.tooltips.custom = this.customTooltip
    },
    selectYear (year) {
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      let price = 0
      for (let i=0; this.invoices.count > i; i++) {            
        if (this.invoices.data[i].date.split('-')[0] == year) {
          if (price == 0) {
            price = this.invoices.data[i].price
          }
          if (i < (this.invoices.count-1) ) {
            if (this.invoices.data[i].date.split('-')[1] == this.invoices.data[i+1].date.split('-')[1]) {
              price = price + this.invoices.data[i+1].price
            } else {
              this.chartdata.labels.push([Math.round((price + Number.EPSILON) * 100)/100 + ' €', this.invoices.data[i].date.split('-')[1] + '-' + this.invoices.data[i].date.split('-')[0]])
              this.chartdata.datasets[0].data.push(price)
              price = 0
            }
          } else {
            this.chartdata.labels.push([Math.round((price + Number.EPSILON) * 100)/100 + ' €', this.invoices.data[i].date.split('-')[1] + '-' + this.invoices.data[i].date.split('-')[0]])
            this.chartdata.datasets[0].data.push(price)
            price = 0
          }
        }
      }
      this.renderChart(this.chartdata, this.options)  
    },
    customTooltip (tooltipModel) {
      if ((tooltipModel.title) && (tooltipModel.body)) {
        let data = tooltipModel.title[0].split(',')
        tooltipModel.body[0].lines = data
        tooltipModel.title = []
      }
      return tooltipModel
    }
  },
  watch: {
    year () {
      this.selectYear(this.year)
    }
  }
}
</script>

<style>
</style>