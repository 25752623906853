<template>
  <div class="newpassword">
      <h1 class="titulo">Área de Clientes <br> <span class="titulo-pequeño">Establecer Contraseña</span></h1>

      <div class="newpassword-content">
        <h2 class="titulo-acceso">            
          <img id="ico-password" alt="password" src="../assets/ico-password.svg" width="45">
          <img id="ico-password-ok" class="hide" alt="password" src="../assets/ico-ok.svg" width="45">
          <br>
          CONTRASEÑA
        </h2>
        <input id="input-password" class="input-password" type="password" name="password" placeholder="Nueva contraseña" size="small" v-model="password" />
        <br>
        <input id="input-re-password" class="input-password" type="password" name="re-password" placeholder="Repite la nueva contraseña" size="small" v-model="rePassword" />
        <br>
        <div id="texto-password" class="texto-password">La contraseña deberá tener 8 dígitos y combinar como mínimo números, mayusculas y minúsculas</div>
        <div id="error-password" class="error-password">{{ error }}</div>
        <div id="boton-password" class="boton-password" @click="send()">Enviar</div>
        <div id="boton-access" class="boton-access" @click="access()">Iniciar sesión</div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'newpassword',
  props: ['saleforceid', 'token'],
  data () {
    return {
      password: '',
      rePassword: '',
      error: ''
    }
  },
  mounted () {
  },   
  methods: {
    send () {
      if (this.validatePassword()) {
        this.error = 'Enviando datos ...'
        document.getElementById('boton-password').classList.add("hide");        
        let payload = {
          saleforceid: this.saleforceid,
          token: this.token,
          password: this.password,       
        }
        this.$http.post('/api/set_password', payload).then(response => {
          if(response.data['result'] == 'ok') {
            if(response.data['data']['updated'] == true) {
              this.error = ''
              document.getElementById("ico-password").classList.add("hide");
              document.getElementById("ico-password-ok").classList.remove("hide");
              document.getElementById("ico-password-ok").classList.add("show");
              document.getElementById("input-password").remove();
              document.getElementById("input-re-password").remove();
              document.getElementById("texto-password").innerHTML="Cambiada correctamente";
              document.getElementById("boton-password").remove();
              document.getElementById("boton-access").classList.add("show");
            } else {
              this.error = this.getError(response.data['data'])
              document.getElementById('boton-password').classList.remove("hide");
            }
          } else {
            this.error = this.getError(response.data['data'])
            document.getElementById('boton-password').classList.remove("hide");
          }
        }).catch(() => {
          this.error = this.getError('Error')
          document.getElementById('boton-password').classList.remove("hide");
        })
      }
    },
    validatePassword () {
       if (this.password == '' || this.rePassword == '') {
        this.error = 'Faltan campos por rellenar'
        return false
      } else if (this.password !== this.rePassword) {
        this.error = 'Las contraseñas no coinciden'
        return false
      } else if (this.password.length < 8 || this.password.length > 8 || this.password.search(/[A-Z]/) == -1 || this.password.search(/[a-z]/) == -1) {
        this.error = 'Nueva contraseña no válida'        
        return false        
      }
      this.error = ''
      return true
    },
    getError (error) {
      if (error == 'Invalid token') return 'Sesión caducada. Inicie sesión de nuevo'
      if (error == 'Invalid password format') return 'Nueva contraseña incorrecta'
      if (error == 'Invalid Password') return 'Contraseña incorrecta'
      if (error == 'Error') return 'Se ha producido un error al cambiar la contraseña'
      return 'No se ha podido cambiar la contraseña'
    },    
    access () {
      this.$router.push("/")
    }
  },
  computed: {
    ...mapGetters(['logged'])
  },  
}
</script>

<style>
.titulo-acceso {
  margin: 50px 0 15px 0;
  text-align: center;
}

.newpassword-content {
  text-align: center;
}

#ico-password-ok {
  margin: auto;
}

.input-password {
  width: 85%;
  margin: 15px auto;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  color: #BABABA;
  padding: 8px;
}

.texto-password {
  margin: 5px auto;
  text-align: center;
  font-size: 16px;
  width: 90%;
  cursor: pointer;
}

.error-password {
  margin: 50px auto 0 auto;
  text-align: center;
  color: var(--fucsia-1);
  font-size: 18px;
  width: 90%;
}

.boton-password {
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;  
}

.boton-access {
  display: none;
  margin: 15px auto;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 20px;
  width: 90%;
  border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;    
}

@media (min-width: 768px) {
  .input-password, .texto-password, .boton-password, .boton-access {
    width: 20%;
  }
}
</style>

